import React, {useState, useEffect, useRef, useContext} from "react";
import pluralize from 'pluralize'
import {
    Popconfirm,
    Modal,
    Button,
    message,
    Space,
    Tooltip,
    Divider,
    Progress,
    Typography,
    Popover,
    Badge,
    Dropdown,
    Menu, Alert, Tag, Table, List, Skeleton
} from "antd";
import {Formik} from "formik";
import {useSelectedAssetsDispatch, useSelectedAssetsState} from "../../contexts/SelectedAssetsContext";
import api from "../api";
import {Checkbox, Form, Rate, FormItem, Select, Input, Radio} from "formik-antd";
import DownloadOutlined from "@ant-design/icons/lib/icons/DownloadOutlined";

import fileDownload from 'js-file-download';
import CloudDownloadOutlined from "@ant-design/icons/lib/icons/CloudDownloadOutlined";
import {useAssetsState} from "../../contexts/AssetsContext";
import {
    ApiOutlined,
    CheckOutlined,
    LoadingOutlined, LockOutlined,
    MacCommandFilled, FolderOpenOutlined, FolderViewOutlined, DropboxOutlined, FilterOutlined, FormOutlined,
    EllipsisOutlined
} from "@ant-design/icons";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import useCurrentOrg from "../helpers/useCurrentOrg";
import {
    BoxFilePicker,
    ConnectBoxButton,
    ConnectDropboxButton,
    ConnectGoogleDriveButton, GoogleDrivePicker, useConnectBoxButton, useConnectDropboxButton, useConnectGoogleDrive,
    useDropboxChooserButton
} from "./UploadHeader";
import {useAbility} from "@casl/react";
import {AbilityContext} from "../helpers/Can";
import useDrivePicker from "react-google-drive-picker";
import AutoLinker from "../helpers/AutoLinker";
import VerticalSpace from "../helpers/VerticalSpace";
import {BoxIcon, GoogleDriveIcon, LightroomIcon, WatermarkIcon} from "../helpers/icons";
import {useFilters} from "../helpers/useFilters";
import {AccessRequestModal} from "./AccessRequestButton";
import {useTranslation} from "react-i18next";
import {ConnectLightroomButton, useConnectLightroom} from "~/components/registration/Settings";
import useCurrentUser from "~/components/helpers/useCurrentUser";
import TextMuted from "@/components/helpers/TextMuted";
import {useAssetGroupState} from "@/contexts/AssetGroupContext";
import DownOutlined from "@ant-design/icons/lib/icons/DownOutlined";
import {useAssetActions} from "~/contexts/AssetActionsContext";
import HelpPopover from "@/components/HelpPopover";

export default ({asset=null, size='small', block, canDownload, storageFolder=null, menu=false, noTooltip=false, elipsis=false})=> {
    const {t} = useTranslation();

    const currentOrg = useCurrentOrg()

    const currentUser = useCurrentUser()
    const {currentAssetGroup} = useAssetGroupState()
    const manageableAssetGroup =
        currentAssetGroup?.type === 'StorageFolder' &&
        currentUser?.user_group_ids?.indexOf(currentAssetGroup?.user_group_id) === -1;

    const {
        setDownloadModalOpen,
        setDownloadAsset,
        setDownloadStorageFolder
    } = useAssetActions()

    const onClick = e => {
        e.stopPropagation()
        setDownloadModalOpen(true)
        setDownloadAsset(asset)
        setDownloadStorageFolder(storageFolder)
    }

    const {selectedAssetIds} = useSelectedAssetsState();
    const {assets} = useAssetsState()
    let anyDownloadable = canDownload || manageableAssetGroup || asset?.downloadable;

    for(const id of selectedAssetIds) {
        const asset = assets[id]
        if(!asset || asset.downloadable) {
            anyDownloadable = true
            break
        }
    }

    if(!anyDownloadable && !currentOrg?.enable_access_requests) return <></>

    return (
        <>
            {menu ? (
                <a style={{width:'100%', color: 'inherit'}} onClick={onClick}> <CloudDownloadOutlined/> {t('button-download','Download')}{elipsis && '...'} </a>
            ) : (
                <Tooltip title={isBrowser() && !asset && !noTooltip && <><MacCommandFilled/> Shift S</>} placement={'top'}>
                    <Button
                        size={size}
                        icon={<CloudDownloadOutlined/>}
                        onClick={onClick}
                        type={block ? 'text' : 'primary'}
                        block={block}
                        id={'bulk-download-btn'}
                    >
                    {isBrowser() && t('button-download','Download')}{elipsis && '...'}
                    </Button>
                </Tooltip>
            )}
        </>
    )
}

const DownloadModal = ()=> {
    const {t} = useTranslation();

    const {
        downloadModalOpen: visible,
        setDownloadModalOpen: setVisible,
        downloadAsset: asset,
        setRequestAssetIds,
        setAccessRequestModalOpen,
        downloadStorageFolder,
        setDownloadStorageFolder
    } = useAssetActions()

    const currentOrg = useCurrentOrg()
    const currentUser = useCurrentUser()

    const ability = useAbility(AbilityContext);

    const {selectedAssetIds} = useSelectedAssetsState();
    const assetIds = asset ? [asset.id] : selectedAssetIds;

    try {
        // Pre-load google JS
        useDrivePicker();
    } catch(e) {}

    const {setFilters} = useFilters()

    const [restrictedSizes, setRestrictedSizes ] = useState([])

    return (
        (<Formik
            initialValues={{size:'original', integration: null, restricted_handling: 'best_available'}}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
                values.storage_folder_id = downloadStorageFolder?.id
                values.asset_ids = assetIds;
                values.via = 'web'

                setDownloadStorageFolder(null)

                api.post( '/api/downloads', {download: values}).then(res => {

                    const after = ()=>{
                        setVisible(false)

                        actions.resetForm();
                        actions.setSubmitting(false);
                    }

                    if(res.data.integration) {
                        message.success(`${res.data.integration.toProperCase()} ${t('message-download-staring', 'download is starting!')}`)

                    } else if(res.data.token) {
                        message.success(t('download-starting','Your download will start shortly...'))

                        const url = `/api/downloads/${res.data.token}`
                        const a = document.createElement("a")
                        a.setAttribute('href', url)
                        a.setAttribute('download', res.data.filename)
                        a.setAttribute('_target', 'blank')
                        a.style.display = 'none';
                        document.body.appendChild(a)
                        a.click()
                        document.body.removeChild(a);
                    } else {
                        message.error(t('error-download-error','There an error preparing your download.'))
                    }

                    if(values.restricted_handling === 'skip_and_request_access') {
                        api.post(`/api/assets/search`, {
                            all_ids: true,
                            selected_asset_ids: assetIds,
                            download_levels: _.uniq(restrictedSizes)
                        }).then(res => {
                            after()
                            console.log('requestAccess', res.data.ids)
                            setRequestAssetIds(res.data.ids)
                            setAccessRequestModalOpen(true)
                        })
                    } else {
                        after()
                    }

                }).catch((err)=>{
                    console.log(err)
                    actions.setSubmitting(false);
                })
            }}
        >
            {({values, submitForm, handleSubmit, isSubmitting, setFieldValue}) => {
                const radioStyle = {
                    display: 'block',
                    height: '30px',
                    lineHeight: '30px',
                };

                const submit = ()=> setTimeout(submitForm, 100) // Unclear why setTimeout is needed here...

                const onLightroomPick = () => {
                    setFieldValue('integration', 'lightroom')
                    submit()
                }

                const onBoxFolderPick = items => {
                    console.log(items)
                    setFieldValue('integration', 'box')
                    setFieldValue('box_folder_id', items[0].id)
                    submit()
                }

                const onDropboxFolderPick = items => {
                    console.log('onDropboxFolderPick', items)
                    setFieldValue('integration', 'dropbox')
                    setFieldValue('dropbox_folder_id', items[0].id)
                    submit()
                }

                const onGoogleDriveFolderPick = items => {
                    console.log(items)
                    setFieldValue('integration', 'google_drive')
                    setFieldValue('google_drive_folder_id', items[0].id)
                    submit()
                }

                const canDownloadAll = !currentOrg?.federation && ability.can('download_all', 'Asset')

                const [aggs, setAggs] = useState()

                useEffect(()=>{
                    if(!visible || aggs || canDownloadAll) return;

                    setAggs(false)
                    const data = {
                        selected_asset_ids: assetIds,
                        selection: 'selected',
                        include_download_aggregates: true,
                        download_aggregates_only: true,
                        include_totals: true,
                        aggregates_only: true
                    }

                    api.post('/api/assets/search', data).then(res => {
                        setAggs(res.data.aggs)
                        setFieldValue('restricted_handling', 'best_available')

                        // Find highest allowed download size and set default:
                        for(const size of sizes) {
                            if(res.data.aggs[`download_level_${size}`]?.doc_count || 0 > 0) {
                                setFieldValue('size', size)
                                break;
                            }
                        }
                    })

                }, [visible])

                const [cropPresets, setCropPresets] = useState()

                useEffect(()=>{
                    if(currentOrg && currentUser) {
                        api('/api/crop_presets?enabled=true').then(res => {
                            setCropPresets(res.data)
                        })
                    }
                }, [currentOrg?.id])

                let totalDownloadable = 0

                if(aggs) {
                    for(const level of ['full', 'full_watermark', 'medium', 'medium_watermark','original','small','small_watermark'])
                        totalDownloadable += aggs[`download_level_${level}`]?.doc_count || 0
                }

                const sizes = ['original', 'full', 'medium', 'small', 'none']

                let bestSize = 'none'
                if(aggs) {
                    for (const size of sizes) {
                        if (aggs[`download_level_${size}`]?.doc_count) {
                            bestSize = size;
                            break;
                        }
                    }
                }

                const getSizeAggs = size => {
                    const n = aggs && aggs[`download_level_${size}`]?.doc_count
                    const wm_n = aggs && aggs[`download_level_${size}_watermark`]?.doc_count

                    let selectedSize = values.size;
                    if(values.size.match(/crop/)) {
                        const id = parseInt(values.size.match(/(\d+)$/)[1])
                        selectedSize = _.find(cropPresets, {id: id})?.closest_size
                    }

                    const restricted = sizes.indexOf(selectedSize) < sizes.indexOf(size) && (n > 0 || wm_n > 0)

                    return {n, wm_n, restricted}
                }

                const DownloadAgg = ({size, children}) => {
                    const {n, wm_n, restricted} = getSizeAggs(size)

                    const fontColor = restricted || (size === 'none' && n > 0) ? Colors.red : (n === 0 ? 'LightGray' : 'auto' )
                    const tagColor = restricted || (size === 'none' && n > 0) ? 'red' : 'default'

                    return (
                        <tr style={{margin: '.5em 0', color: fontColor}} id={`restricted-indicator-${size}-${tagColor}`}>
                            <td>{n > 0 && <Tag color={tagColor}>{n}</Tag>}</td>
                            <td>{children}</td>
                            <td>
                                {!!(wm_n && wm_n > 0) && (
                                    <Tooltip title={t('tooltip-watermarked','Watermarked')} zIndex={9999}>
                                        <Tag color={'red'} icon={<WatermarkIcon/>} id={`watermark-indicator-${size}`}>
                                            {wm_n}
                                        </Tag>
                                    </Tooltip>
                                )}
                            </td>
                        </tr>
                    )
                }

                useEffect(()=>{
                    if(!aggs) return;
                    console.log('setting Restricted sizes')

                    const newRestrictedSizes = []

                    sizes.map(size => {
                        const {restricted} = getSizeAggs(size)

                        if(restricted) newRestrictedSizes.push(size)
                    })

                    setRestrictedSizes(newRestrictedSizes)
                }, [aggs, values.size])

                const clickDownloadBlocked = ()=>{
                    setFilters(false, {download_levels: _.uniq(restrictedSizes)})
                    setFilters(false, {selection: 'selected'})
                    setVisible(false)
                }

                const onCancel = ()=>{
                    setVisible(false)
                    setDownloadStorageFolder(null)
                    setAggs()
                }

                const resetRestricted = ()=>{
                    console.log('resetRestricted')
                    setRestrictedSizes([])
                }

                const anyIntegrations = currentOrg?.dropbox_token || currentOrg?.box_token || currentOrg?.lightroom_account
                const allIntegrations = currentOrg?.dropbox_token && currentOrg?.box_token && currentOrg?.lightroom_account

                const enabled = canDownloadAll || totalDownloadable > 0 || (restrictedSizes.length === 0 && aggs)

                const requestAccessNote = size => !canDownloadAll && sizes.indexOf(bestSize) > sizes.indexOf(size) && <Tag>{t('request-access', 'Request Access')}</Tag>

                return (
                    (<Modal
                        zIndex={2000}
                        onCancel={onCancel}
                        open={visible}
                        title={<><CloudDownloadOutlined/> {t('download','Download')} {downloadStorageFolder?.name || <>{assetIds.length} {t(pluralize('Asset', assetIds.length))}</>}</>}
                        destroyOnClose
                        width={isMobile() ? '100%' : 600}
                        footer={()=>{
                            const items = []

                            const hasDropbox = !!currentOrg?.dropbox_token
                            const {open: openDropbox, modal: dropboxModal} = useDropboxFolderPicker(onDropboxFolderPick)
                            const clickConnectDropbox = useConnectDropboxButton(openDropbox)

                            if(hasDropbox) {
                                items.push({
                                    key: 'dropbox',
                                    icon: <DropboxOutlined/>,
                                    label: 'Dropbox',
                                    onClick: openDropbox
                                })
                            }

                            const hasBox = !!currentOrg?.box_token
                            const {open: openBox, modal: boxModal} = useBoxFolderPicker('download-modal', onBoxFolderPick)
                            const clickConnectBox = useConnectBoxButton(openBox)

                            if(hasBox) {
                                items.push({
                                    key: 'box',
                                    icon: <BoxIcon/>,
                                    label: 'Box.com',
                                    onClick: openBox
                                })
                            }

                            const hasGoogleDrive = !!currentOrg?.google_drive_access_token

                            const {open: openGoogleDrive, modal: googleDriveModal} = useGoogleDriveFolderPicker(onGoogleDriveFolderPick)
                            const clickConnectGoogleDrive = useConnectGoogleDrive(openGoogleDrive)

                            if(hasGoogleDrive) {
                                items.push({
                                    key: 'google_drive',
                                    icon: <GoogleDriveIcon style={{marginRight:8}}/>,
                                    label: 'Google Drive',
                                    onClick: openGoogleDrive
                                })
                            }

                            const hasLightroom = currentOrg?.lightroom_account
                            if(hasLightroom) {
                                items.push({
                                    key: 'lightroom',
                                    icon: <LightroomIcon/>,
                                    label: (
                                        <Tooltip title={t('download-to-lightroom-note','Download to Lightroom? Note: Only Image and Video files will be sent.')}>
                                            Lightroom
                                        </Tooltip>
                                    ),
                                    onClick: onLightroomPick
                                })
                            }

                            if(anyIntegrations && !allIntegrations) {
                                items.push({type:'divider'})
                            }

                            if(!hasDropbox) {
                                items.push({
                                    key: 'add-dropbox',
                                    icon: <DropboxOutlined/>,
                                    label: t('connect-dropbox', 'Connect Dropbox'),
                                    onClick: clickConnectDropbox
                                })
                            }

                            if(!hasBox) {
                                items.push({
                                    key: 'add-box',
                                    icon: <BoxIcon/>,
                                    label: t('connect-box', 'Connect Box.com'),
                                    onClick: clickConnectBox
                                })
                            }

                            if(!hasGoogleDrive) {
                                items.push({
                                    key: 'add-google-drive',
                                    icon: <GoogleDriveIcon style={{marginRight:8}}/>,
                                    label: t('connect-google-drive', 'Connect Google Drive'),
                                    onClick: clickConnectGoogleDrive
                                })
                            }

                            const clickConnectLightroom = useConnectLightroom()

                            if(currentUser?.enable_lightroom && !currentOrg?.lightroom_account) {
                                items.push({
                                    key: 'add-lightroom',
                                    icon: <LightroomIcon/>,
                                    label: t('connect-lightroom', 'Connect Lightroom'),
                                    onClick: clickConnectLightroom
                                })
                            }

                            return (
                                <Space>
                                    <Button
                                        loading={isSubmitting}
                                        onClick={submitForm}
                                        icon={<DownloadOutlined/>}
                                        disabled={!enabled}
                                        id={'download-modal-download-button'}
                                    >
                                        {t('button-download','Download')} {assetIds.length > 1 && 'Zip'}
                                    </Button>

                                    {currentUser && (
                                        <>
                                            <Dropdown
                                                disabled={!enabled}
                                                trigger={['hover','click']}
                                                getPopupContainer={e => e.parentElement}
                                                overlay={ <Menu style={{width:225}} items={items}/> }
                                                overlayStyle={{zIndex:2001}}
                                            >
                                                <Button
                                                    icon={<ApiOutlined/>}
                                                    loading={isSubmitting}
                                                >
                                                    {t('button-integrations','Integrations')} <DownOutlined/>
                                                </Button>
                                            </Dropdown>

                                            {dropboxModal}
                                            {boxModal}
                                            {googleDriveModal}
                                        </>
                                    )}
                                </Space>
                            )
                        }}
                    >
                        <Form onSubmit={handleSubmit} layout="vertical">
                            <VerticalSpace>
                                {!canDownloadAll && (
                                    <>
                                        <div>{t('access-restrictions-listed-parens','Listed below are any access restrictions for the selected file(s).')}</div>

                                        <Skeleton active loading={!aggs}>
                                            <strong>{t('maximum-size-allowed','Maximum Sizes Allowed')}:</strong>

                                            <table cellPadding={5}>
                                                <tbody>
                                                    <DownloadAgg size={'original'}>{t('original-file','Original File')}</DownloadAgg>
                                                    <DownloadAgg size={'full'}>{t('full-resolution','Full Resolution')}</DownloadAgg>
                                                    <DownloadAgg size={'medium'}>{t('medium','Medium Resolution')}</DownloadAgg>
                                                    <DownloadAgg size={'small'}>{t('small','Small Resolution')}</DownloadAgg>
                                                    <DownloadAgg size={'none'}>{t('download-prohibited','Download Prohibited')}</DownloadAgg>
                                                </tbody>
                                            </table>
                                        </Skeleton>

                                        <Divider style={{margin:'.5em 0'}}/>
                                    </>
                                )}

                                <label>
                                    <strong>{t('desired-size', 'Desired Size')}:</strong>
                                    <HelpPopover code={'download-desired-size'}/>
                                </label>

                                <div id={'download-size-select'}>
                                    <Skeleton paragraph={{rows:1}} loading={!canDownloadAll && !aggs} active>
                                        <Select name={'size'} style={{width:'100%'}} onChange={resetRestricted}>
                                            <List.Item value={'original'}>{t('original-file','Original File')} {requestAccessNote('original')}</List.Item>
                                            <List.Item value={'full'}>{t('full-resolution','Full Resolution')} {requestAccessNote('full')}</List.Item>
                                            <List.Item value={'medium'}>{t('medium-resolution','Medium Resolution')} {requestAccessNote('medium')} <TextMuted small>{currentOrg?.medium_download_size}px</TextMuted></List.Item>
                                            <List.Item value={'small'}>{t('small-resolution','Small Resolution')} {requestAccessNote('small')} <TextMuted small>640px</TextMuted></List.Item>

                                            {cropPresets?.map(p => (
                                                <List.Item
                                                    value={`crop-preset-${p.id}`}
                                                    id={`crop-preset-${p.id}`}
                                                    key={`crop-preset-${p.id}`}
                                                >
                                                    {p.name} <TextMuted small>{p.width} x {p.height}px</TextMuted>
                                                </List.Item>
                                            ))}
                                        </Select>
                                    </Skeleton>
                                </div>

                                {(restrictedSizes.length > 0 || getSizeAggs('none')?.n > 0) && (
                                    <>
                                        <Divider style={{margin:'.5em 0'}}><LockOutlined/> {t('restricted-file-handling','Restricted File Handling')}</Divider>
                                        <VerticalSpace>
                                            <Button type={'primary'} ghost icon={<FilterOutlined/>} block onClick={clickDownloadBlocked}>
                                                {t('button-show-restricted-files','Show Restricted Files')}
                                            </Button>
                                            <Button type={'primary'} icon={<FormOutlined/>} block onClick={() => setAccessRequestModalOpen(true)} id={'request-access-button'}>
                                                {t('button-request-access-for-restricted-files','Request Access for Restricted Files')}
                                            </Button>

                                            <Select name={'restricted_handling'} style={{width:'100%'}}>
                                                <List.Item value={'best_available'}>{t('download-best-available','Download Best Available')}</List.Item>
                                                <List.Item value={'skip_restricted'}>{t('skip-restricted','Skip Restricted')}</List.Item>
                                                <List.Item value={'skip_and_request_access'}>{t('skip-restricted-and-request-access','Skip Restricted and Request Access')}</List.Item>
                                            </Select>
                                        </VerticalSpace>
                                    </>
                                )}

                                <Divider style={{margin:'.5em 0'}}/>

                                <Checkbox name={'watermarked'} disabled={values.size == 'original'}>{t('add-watermark-option','Add Watermark?')}</Checkbox>

                                {currentOrg?.enable_download_statement && (
                                    <Alert
                                        type={'info'}
                                        showIcon
                                        description={<AutoLinker text={currentOrg.download_statement}/>}
                                        style={{marginTop:'2em', whiteSpace: 'pre-line'}}
                                    />
                                )}
                            </VerticalSpace>
                        </Form>
                    </Modal>)
                );
            }}
        </Formik>)
    );
}

export {DownloadModal}

const DropboxFolderPicker = ({launch, onPick, modalTitle, chooseRootLabel, children})=> {
    const {open, modal} = useDropboxFolderPicker(onPick, modalTitle, chooseRootLabel)

    return (<>
        <a onClick={open}>
            {children || <><DropboxOutlined/> Dropbox</>}
        </a>

        {modal}
    </>);
}

const useDropboxFolderPicker = (onPick, modalTitle=null, chooseRootLabel=null) => {
    const {t} = useTranslation();

    const [modalVisible, setModalVisible] = useState()

    const options = {
        multiselect: false,
        folderselect: true,
        extensions: ['.h'],
        success: items => {
            console.log(items)
            if(!items[0].isDir) {
                message.error(t('error-select-folder-not-file','Please select a Folder, not a File.'))
            } else {
                setModalVisible(false)
                onPick(items)
            }
        },
    }

    const chooser = useRef()

    const clickChooser = ()=> {
        if(chooser.current) return;
        chooser.current = Dropbox.choose(options);
    }

    const clickRoot = ()=>{
        setModalVisible(false)
        onPick([{id: 'root'}])
    }

    const modal = (
        <Modal
            zIndex={9999}
            width={isMobile() ? '100%' : 800}
            onCancel={()=> setModalVisible(false)}
            open={modalVisible}
            title={
                <><DropboxOutlined/> {modalTitle || t('download-to-dropbox','Download to Dropbox')}</>
            }
            footer={null}
        >
            <Space>
                <Button onClick={clickRoot} icon={<FolderOpenOutlined/>}>{chooseRootLabel || t('download-to-root-folder','Download To Root Folder')}</Button>
                <Button onClick={clickChooser} icon={<FolderViewOutlined/>}>{t('choose-sub-folder-on-dropbox','Choose Sub-Folder on Dropbox')}</Button>
            </Space>
        </Modal>
    )

    const open = ()=> setModalVisible(true)

    return {modal, open}
}

export {DropboxFolderPicker, useDropboxFolderPicker}

const BoxFolderPicker = ({id, onPick, modalTitle, children})=>{
    const currentOrg = useCurrentOrg()

    const {modal, open} = useBoxFolderPicker(id, onPick, modalTitle)

    if(!currentOrg?.box_token?.access_token) return <></>

    return (<>
        <a onClick={open}>
            {children || <><BoxIcon/> Box.com</>}
        </a>

        {modal}
    </>);
}

const useBoxFolderPicker = (id, onPick, modalTitle=null)=> {
    const {t} = useTranslation();
    const [visible, setVisible] = useState()
    const [token, setToken] = useState()

    const onCancel = ()=> setVisible(false)

    const choose = folder => {
        onPick(folder)
        onCancel()
    }

    const chooseRoot = ()=>{
        choose([{id: 0}])
    }

    useEffect(()=>{
        if(!visible) return

        api.post('/api/integrations/refresh_box').then(res => {
            setToken(res.data.access_token)
        })
    }, [visible])

    useEffect(()=>{
        if(!token) return;

        const contentPicker = new Box.ContentPicker();
        contentPicker.clearCache()

        contentPicker.addListener('choose', choose)
        contentPicker.addListener('cancel', onCancel)

        contentPicker.show('0', token, {
            container: `#${id}`,
            chooseButtonLabel: t('select','Select'),
            type: 'folder',
            logoUrl: 'box',
            canSetShareAccess: false,
            autoFocus: true,
            maxSelectable: 1,
        })

        return ()=>{
            contentPicker.removeAllListeners()
        }
    }, [token])

    const modal = (
        <Modal
            zIndex={2002}
            open={visible}
            onCancel={onCancel}
            width={'75%'}
            bodyStyle={{padding:'0 0 1em 0'}}
            footer={null}
            title={
                <>
                    {modalTitle || t('select-folder-to-download-into-box','Select Folder To Download into on Box.com')}

                    <Button style={{marginLeft:'1em'}} icon={<CheckOutlined/>} onClick={chooseRoot} type={'primary'} ghost>
                        {t('button-select-top-level-root-folder','Select Top Level Root Folder')} (/)
                    </Button>
                </>
            }
        >
            {!token && <LoadingOutlined style={{margin:'1em'}}/>}
            <div id={id} style={{height:'75vh'}}/>
        </Modal>
    )

    const open = ()=> setVisible(true)

    return { modal, open }
}

export {BoxFolderPicker, useBoxFolderPicker}

const GoogleDriveFolderPicker = ({onPick, children, modalTitle, chooseRootLabel}) => {
    const {open, modal} = useGoogleDriveFolderPicker(onPick, modalTitle, chooseRootLabel)

    return (<>
        <a onClick={open}>{children || <><GoogleDriveIcon/> Google Drive</>}</a>

        {modal}
    </>);
}

const useGoogleDriveFolderPicker = (onPick, modalTitle, chooseRootLabel)=>{
    const [openPicker, files, authResponse] = useDrivePicker();

    const [token, setToken] = useState()
    const [modalVisible, setModalVisible] = useState()

    const onCancel = ()=> setModalVisible(false)

    const clickRoot = ()=>{
        setModalVisible(false)
        onPick([{id: 'root'}])
    }

    const click = ()=> {
        if(token) {
            handleOpenPicker(token)

        } else {
            api.post('/api/integrations/refresh_google_drive').then(res => {
                if(res.data.access_token) {
                    setToken(res.data.access_token)
                    handleOpenPicker(res.data.access_token)

                } else {
                    message.error(res.data.error)
                }
            })
        }
    }

    const handleOpenPicker = token => {
        const customViews = [
            new google.picker.DocsView()
                .setIncludeFolders(true)
                .setMimeTypes('application/vnd.google-apps.folder')
                .setSelectFolderEnabled(true)
        ];
        openPicker({
            clientId: Config.googleDriveClientId,
            developerKey: Config.googleDriveDeveloperKey,
            disableDefaultView: true,
            token: token, // pass oauth token in case you already have one
            setIncludeFolders: true,
            setSelectFolderEnabled: true,
            supportDrives: true,
            customViews: customViews, // custom view
        })
    }

    useEffect(() => {
        if(files){
            onPick(files.docs)
            setModalVisible(false)
        }
    }, [files])

    const open = ()=> setModalVisible(true)

    const modal = (
        <Modal
            zIndex={2002}
            open={modalVisible}
            onCancel={onCancel}
            width={'75%'}
            footer={null}
            title={
                <>
                    {modalTitle || 'Select Folder To Download into on Google Drive'}
                </>
            }
        >
            <Space>
                <Button onClick={clickRoot} icon={<FolderOpenOutlined/>}>{chooseRootLabel || 'Download To Root Folder'}</Button>
                {/*<Button onClick={click} icon={<FolderViewOutlined/>}>Choose Sub-Folder on Google Drive</Button>*/}
            </Space>
        </Modal>
    )

    return {
        open,
        modal
    }
}


export {GoogleDriveFolderPicker}
