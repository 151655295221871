import React, {useEffect} from "react";

import {Col, Row} from 'antd';
import {useFormikContext} from 'formik';
import {Form, Input, Select, Switch, Checkbox} from "formik-antd";
import FloatLabel from "../helpers/FloatLabel";
import Text from "antd/lib/typography/Text";
import {useTranslation} from "react-i18next";

export default ({i, preset, fieldName='permission_attributes'}) => {
    const {t} = useTranslation();

    const imageOpts = { small: '640', medium: '1200', full: t('full-size','Full Size'), original: t('original-file','Original File') };
    const videoOpts = { small: '480p', full: t('full-size','Full Size'), original: t('original-file','Original File') };
    const audioOpts = { listen: t('listen-only','Listen Only'), original: t('original-file','Original File') };
    const otherOpts = { view: t('view-only','View Only'), original: t('original-file','Original File') };

    const getFieldName = name => `${fieldName}[${i}].${name}`;
    const { values, setFieldValue } = useFormikContext();

    const getFieldValue = name => values[fieldName][i][name];

    const disableWatermark = getFieldValue('image') === 'original' || getFieldValue('video') === 'original';

    useEffect(() => {
        if(disableWatermark && getFieldValue('watermark')) setFieldValue(getFieldName('watermark'))
    }, [disableWatermark]);

    const name = values[fieldName][i].name
    const disableSizeSelect = preset && name.match(/Original/)

    return (
        <Row gutter={16} style={{marginTop:'1em'}}>
            {!preset && (
                <Col span={24} style={{marginBottom:'1em', marginTop:0}}>
                    <FloatLabel
                        label={t('name','Name')}
                        name={getFieldName('name')}
                        description={t('placeholder-custom-permission','e.g. Custom Permission')}
                        value={name}
                    >
                        <Input size={'large'} required name={getFieldName('name')} autoFocus />
                    </FloatLabel>
                </Col>
            )}

            <div style={{ display: 'none' }}>
                <Input name={getFieldName('id')} />
                {preset && (
                    <>
                        <Input name={getFieldName('preset')} />
                        <Input name={getFieldName('name')} />
                    </>
                )}
            </div>

            <Col lg={4} xs={24}>
                <Switch checkedChildren={t('enabled','Enabled')?.toProperCase()} unCheckedChildren={t('disabled','Disabled')?.toProperCase()} name={getFieldName('enabled')}/>
                <Switch
                    checkedChildren={t('watermark','Watermark')}
                    unCheckedChildren={t('watermark','Watermark')}
                    name={getFieldName('watermark')}
                    disabled={disableWatermark}
                />
                <Switch disabled={preset} checkedChildren={t('download','Download')} unCheckedChildren={t('download','Download')}  name={getFieldName('download')}/>
            </Col>
            <Col lg={5} xs={24}>
                <Form.Item name={getFieldName('image')} label={t('images','Images')} style={{marginBottom:0}}>
                    <Select name={getFieldName('image')} placeholder={t('placeholder-view-only','View Only')} disabled={disableSizeSelect}>
                        {Object.keys(imageOpts).map((opt) =>
                            <Select.Option value={opt} key={opt}>{imageOpts[opt]}</Select.Option>
                        )}
                    </Select></Form.Item>
            </Col>
            <Col lg={5} xs={24}>
                <Form.Item name={getFieldName('video')}  label={t('video','Video')} style={{marginBottom:0}}>
                    <Select name={getFieldName('video')} placeholder={t('placeholder-view-only','View Only')} disabled={disableSizeSelect}>
                        {Object.keys(videoOpts).map((opt) =>
                            <Select.Option value={opt} key={opt}>{videoOpts[opt]}</Select.Option>
                        )}
                    </Select>
                </Form.Item>
            </Col>
            <Col lg={5} xs={24}>
                <Form.Item name={getFieldName('audio')} label={t('audio','Audio')} style={{marginBottom:0}}>
                    <Select name={getFieldName('audio')} placeholder={t('placeholder-view-only','View Only')} disabled={disableSizeSelect}>
                        {Object.keys(audioOpts).map((opt) =>
                            <Select.Option value={opt} key={opt}>{audioOpts[opt]}</Select.Option>
                        )}
                    </Select>
                </Form.Item>
            </Col>
            <Col lg={5} xs={24}>
                <Form.Item name={getFieldName('other')} label={t('other','Other')} style={{marginBottom:0}}>
                    <Select name={getFieldName('other')} placeholder={t('placeholder-view-only','View Only')} disabled={disableSizeSelect}>
                        {Object.keys(otherOpts).map((opt) =>
                            <Select.Option value={opt} key={opt}>{otherOpts[opt]}</Select.Option>
                        )}
                    </Select>
                </Form.Item>
            </Col>

            {!preset && (
                <Col span={24}>
                    <Checkbox name={getFieldName('_destroy')} style={{float:'right'}}>
                        <Text type="danger">
                            {t('remove-question','Remove?')}
                        </Text>
                    </Checkbox>
                </Col>
            )}
        </Row>
    );
}

