import React, {useContext, useEffect, useRef, useState} from "react";
import _ from 'lodash';
import {
    AutoComplete,
    Button,
    Card,
    Col,
    Collapse,
    Divider,
    Drawer,
    List,
    message,
    Row,
    Space,
    Tag,
    Typography,
    Input as AntInput, Modal, Popover, Popconfirm, Skeleton, Alert, TimePicker, Tooltip
} from "antd";
import {
    BulbOutlined, CheckOutlined,
    ClockCircleOutlined,
    CloudUploadOutlined,
    CommentOutlined,
    DeleteOutlined,
    EditOutlined,
    EyeInvisibleOutlined,
    EyeOutlined, FileAddFilled, FileAddOutlined,
    LinkOutlined,
    LoadingOutlined,
    LockOutlined,
    PlusOutlined,
    SaveOutlined,
    SettingOutlined,
    ShareAltOutlined,
    UserAddOutlined,
    UserOutlined,
} from "@ant-design/icons";
import {Field, Switch, Checkbox, Form, FormItem, Input, Select, SubmitButton, DatePicker, Radio} from "formik-antd";
import {FieldArray, Formik} from "formik";
import api from "../api";
import * as Yup from "yup";
import User from "../helpers/User";
import FloatLabel from "../helpers/FloatLabel";
import HelpPopover from "../HelpPopover";
import Links from "./Links"

import {useNavigate, useLocation} from "react-router-dom-v5-compat";
import ContributionDetailsForm from "../manage/ContributionDetailsForm";
import {AbilityContext} from "../helpers/Can";
import EnabledDisabled from "../helpers/EnabledDisabled";
import {useAssetGroupDispatch, useAssetGroupState} from "../../contexts/AssetGroupContext";
import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined";
import {CollectionShareIcon, ShareIcon, UserGroupIcon, WatermarkIcon} from "../helpers/icons";
import TableOutlined from "@ant-design/icons/lib/icons/TableOutlined";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import ReactQuill from "react-quill";
import {AppContext} from "../../contexts/AppContext";
import TimeAgo from "../helpers/TimeAgo";
import useCurrentOrg from "../helpers/useCurrentOrg";
import {AssetMention} from "./CommentThread";
import {useOrgPath} from "../helpers/OrgNavLink";

import day from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
day.extend(utc)
day.extend(timezone)

import useCurrentUser, {useTimezone} from "../helpers/useCurrentUser";
import SharesTable from "../manage/SharesTable";
import VerticalSpace from "../helpers/VerticalSpace";
import {useTranslation} from "react-i18next";

export default ()=> {
    const {t} = useTranslation();
    const {state} = useContext(AppContext);
    const {currentUser} = state;

    const {currentAssetGroup} = useAssetGroupState();
    const assetGroupDispatch = useAssetGroupDispatch();

    const [editingAssetGroup, setEditingAssetGroup] = useState()

    const ability = useContext(AbilityContext);

    const location = useLocation()
    const navigate = useNavigate();

    useEffect(()=>{
        const [x, path, y, id] = location.hash.match(/^#\/(lightboxes|collections|storage_folders)\/((\d+?)\/edit|new)/) || []

        const type = {
            'collections': 'Collection',
            'storage_folders': 'StorageFolder',
            'lightboxes' : 'Lightbox'
        }[path];

        if(path && type) {
            setEditingAssetGroup(id == 'new' ? {type} : {id, type})
        } else {
            setAssetGroup(null)
            setEditingAssetGroup(null)
        }
    }, [location.hash])

    const [userGroups, setUserGroups] = useState();

    const [assetGroup, setAssetGroup] = useState();

    const {id,type} = assetGroup || {};

    const project = location.hash?.match('project=true')

    const route = {
        'Collection': 'collections',
        'StorageFolder': 'storage_folders',
        'Lightbox': 'lightboxes'
    }[editingAssetGroup?.type];

    const parentIdMatch = location.hash.match(/parent_id=(\d+?)($|&)/)
    const parent_id = parentIdMatch && parentIdMatch[1]

    // Only load when Drawer opened:
    const [loadingAssetGroup, setLoadingAssetGroup] = useState()
    useEffect(() => {
        if(!editingAssetGroup || loadingAssetGroup) return;

        const path = editingAssetGroup.id ? `${editingAssetGroup.id}/edit` : 'new';

        const params = {project, parent_id}

        setLoadingAssetGroup(true)
        api.get(`/api/${route}/${path}`, {params}).then((res) => {
            setAssetGroup(res.data);
            setLoadingAssetGroup(false)
        });

        api.get(`/api/user_groups?per_page=10000`).then((res) => {
           setUserGroups(res.data);
        });
    }, [editingAssetGroup?.id, editingAssetGroup?.type]);

    const [width, setWidth] = useState(isMobile() ? '100%' : '50%');

    let modal = null;

    const InviteAlert =
        <Alert type={'warning'} message={t('warning-new-invites-not-sent','New Invites Not Sent')} description={t('new-invites-not-sent-description-add-update','Click the Add Invite button and then click Update.')}/>

    const onCloseDirty = (dirty, values)=> {
        // FIXME: calling onClose directly has no effect
        if(modal) return;

        if(!dirty && searchValue === '') closeDrawer()
        else {
            const hasNewInvites = values.asset_group_invites_attributes?.filter(ia => !ia.id)?.length || searchValue !== ''
            const content = hasNewInvites ?
                InviteAlert :
                t('you-have-unsaved-changes','You have unsaved changes.')

            modal = Modal.confirm({
                zIndex: 1033,
                title: t('cancel-edit','Cancel edit?'),
                icon: <ExclamationCircleOutlined />,
                content: content,
                onOk: closeDrawer,
                onCancel: () => { modal = null },
                cancelText: t('no','No'),
                okText: t('yes','Yes')
            })
        }
    }

    const checkInvitesDirty = (onOk, onCancel) =>{
        modal = Modal.confirm({
            zIndex: 1033,
            title: t('warning','Warning'),
            icon: <ExclamationCircleOutlined />,
            content: InviteAlert,
            onOk: onOk,
            onCancel: () => {
                modal = null
                onCancel()
            },
            cancelText: t('cancel','Cancel'),
            okText: t('ok','Ok')
        })
    }

    const closeDrawer = ()=>{
        location.hash = null
        navigate(location)
        setAssetGroup(null);
        setEditingAssetGroup(null)
    }

    // ====================
    // Invite Member Search
    // ====================

    const [searchOptions, setSearchOptions] = useState([])

    const lastSearchValue = useRef();
    const search = _.debounce((value)=>{
        lastSearchValue.current = value;

        if(value == '') return setSearchOptions([]);

        api('/api/memberships/search', {params: {q: value}}).then(res => {
            // console.log(value, res.data)
            if(value != lastSearchValue.current) return;

            const options = res.data.map(m => {
                return {
                    value: m.user.email,
                    label: <User user={m.user} showEmail/>
                }
            });

            if(!options.length) {
                options.push({
                    value: value,
                    label: <><em>Invite {value}</em></>
                })
            }

            setSearchOptions(options);
        });
    }, 250)

    const [searchValue, setSearchValue] = useState('');

    // Avoid null validation error:
    if(assetGroup && !assetGroup.name) assetGroup.name = ''

    // Default checked for inherit permissions
    userGroups?.map(group => {
        if(
            !assetGroup ||
                !assetGroup.asset_group_admins_by_user_group ||
                assetGroup.asset_group_admins_by_user_group[group.id]
        ) return

        assetGroup.asset_group_admins_by_user_group[group.id] = {children_inherit: true}
    })

    const [overridenUserGroupIds, setOverridenUserGroupIds] = useState([])
    const overridePermissions = (id)=> {
        if(overridenUserGroupIds.indexOf(id) != -1)
            setOverridenUserGroupIds(_.without(overridenUserGroupIds,id))
        else
            setOverridenUserGroupIds(overridenUserGroupIds.concat(id))
    }

    const [shareLinksCount, setShareLinksCount] = useState()

    const getOrgPath = useOrgPath()
    const currentOrg = useCurrentOrg()

    const roles = ['admin', 'global_content', 'global_library', 'general', 'restricted']
    const canEnableUploads = currentOrg && roles.indexOf(currentOrg.role_level) <= roles.indexOf(currentOrg.lightbox_upload_role_level)

    const submit = (values, actions) => {
        if(!values.id) {
            // For Project creation:
            values.project = project
            values.parent_id = parent_id
        }

        const field = {
            'Collection': 'collection',
            'StorageFolder': 'storage_folder',
            'Lightbox': 'lightbox'
        }[editingAssetGroup?.type];

        if(!values.enable_contribution)
            delete values.contribution_attributes

        if(!values.poster_image_guid || values.poster_image_guid === '') values.poster_image_guid = 0;

        let data = {}
        data[field] = values;

        api({
            method: id ? 'put' : 'post',
            url: id ? `/api/${route}/${id}` : `/api/${route}`,
            data: data
        }).then((res)=>{
            console.log('id',id);
            actions.setSubmitting(false)
            message.success(`${values.name} has been ${id ? 'updated' : 'created'}!`)
            setAssetGroup(res.data);

            if(currentAssetGroup?.id == res.data.id) {
                assetGroupDispatch({type:'updateCurrentAssetGroup', assetGroup: res.data})

                if(currentAssetGroup.slug != res.data.slug) {
                    setTimeout(()=>{
                        navigate(getOrgPath(`/explore/${route}/${res.data.slug}`))
                    }, 100)
                }
            }

            actions.resetForm({});

            if(!id) assetGroupDispatch({type:'newAssetGroup', assetGroup: res.data})

            // onSave && onSave(res.data);
            closeDrawer()

        }).catch((error)=>{
            console.error(error)
            message.error(`Error: ${JSON.stringify(error.data)}`)
            actions.setSubmitting(false)
        })
    }

    return (
        (<Formik
            initialValues={assetGroup || {}}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
                if(searchValue && searchValue !== '') {
                    checkInvitesDirty(()=> {
                        submit(values, actions)
                    }, ()=>{
                        actions.setSubmitting(false)
                    })
                } else {
                    submit(values, actions)
                }
            }}
            validationSchema={
                Yup.object({
                    name: Yup.string().required(true), // TODO: check uniqueness
                })
            }
        >
            {({values, submitForm, dirty, isValid, isSubmitting, setFieldValue}) => {
                const type = assetGroup?.type
                const subType = assetGroup?.sub_type
                const typeName = assetGroup?.sub_type == 'project' ? t('project','Project') : t(type, type);
                const subtitle = assetGroup?.parent && <em>Within {assetGroup.parent.name}</em>

                const autoFocusInput = useRef(null);

                const timezone = useTimezone()

                const changeImageVideoPermission = value => {
                    if(value === 'original' && values.watermark_all) {
                        setFieldValue('watermark_all', false)
                    }
                }

                return (
                    (<Drawer
                        zIndex={1033}
                        width={width}
                        bodyStyle={{padding: isMobile() ? '.5em' : 'auto'}}
                        placement={'right'}
                        open={!!assetGroup || loadingAssetGroup}
                        onClose={() => onCloseDirty(dirty, values)}
                        afterOpenChange={vis => vis && autoFocusInput.current?.focus() }
                        title={<strong><SettingOutlined/> {assetGroup?.id ? t('manage','Manage') : t('new','New')} {typeName}{assetGroup?.organizer ? ` ${t('organizer','Organizer')}` : ''} {subtitle}</strong>}
                        footer={
                            <div style={{textAlign: 'right',}}>
                                <Space direction={'horizontal'}>
                                    <SubmitButton type={'primary'} htmlType={'submit'} onClick={submitForm} disabled={!dirty || !isValid} loading={isSubmitting}>
                                        <SaveOutlined/>
                                        {id ? t('button-update','Update') : t('save','Save')}
                                    </SubmitButton>
                                </Space>
                            </div>
                        }
                    >
                        <Skeleton active loading={loadingAssetGroup}>
                            <Form layout='vertical'>
                                <FormItem required showValidateSuccess name='name'>
                                    <FloatLabel label={t('name','Name')} name={'name'} value={values.name} description={t('placeholder-asset-group-name','e.g. Marketing')}>
                                        <Input size={'large'} required name='name' ref={autoFocusInput} autoFocus autoComplete={'off'}/>
                                    </FloatLabel>
                                </FormItem>

                                <FormItem name='description' style={{marginTop:'.5em'}}>
                                    <FloatLabel label={t('description','Description')} name={'description'} value={values.description} description={t('optional','Optional.')}>
                                        <Input.TextArea rows={2} name='description'/>
                                    </FloatLabel>
                                </FormItem>

                                {assetGroup?.type === 'Collection' && (
                                    <FormItem name='featured' style={{marginTop:'.5em'}}>
                                        <label><strong>{t('featured-collection','Featured Collection')}:</strong></label>
                                        <br/>
                                        <Switch name='featured' checkedChildren={t('enabled','Enabled').toProperCase()} unCheckedChildren={t('disabled','Disabled').toProperCase()} />
                                    </FormItem>
                                )}

                                {assetGroup?.type === 'Lightbox' && (
                                    <>
                                        {assetGroup.sub_type == 'project' && (
                                            <Row style={{margin:'1em 0'}}>
                                                <Col span={24}>
                                                    <Checkbox name={'children_inherit_permissions'}>
                                                        {t('project-children-inherited-settings-note','Create all Project Lightboxes with these same settings. (Can be overridden on individual lightboxes.)')}
                                                        <HelpPopover code={'project-settings-inheritance'}/>
                                                    </Checkbox>
                                                </Col>
                                            </Row>
                                        )}

                                        {/*<Row style={{marginTop:'.5em'}}>*/}
                                        {/*    <Col span={12}>*/}
                                        {/*        <FormItem name='expires_at'>*/}
                                        {/*            <label htmlFor={'expires_at'}><strong>Expiration:</strong></label>*/}
                                        {/*            <br/>*/}
                                        {/*            <DatePicker name={'expires_at'} showTime/>*/}
                                        {/*        </FormItem>*/}
                                        {/*    </Col>*/}

                                        {/*    <Col span={12}>*/}
                                        {/*        <strong>On Expiration:</strong>*/}
                                        {/*        <br/>*/}
                                        {/*        <Checkbox name={'lock_on_expiration'}>*/}
                                        {/*            Lock*/}
                                        {/*        </Checkbox>*/}
                                        {/*        <br/>*/}
                                        {/*        <Checkbox name={'remove_collaborators_on_expiration'}>*/}
                                        {/*            Remove Invitees*/}
                                        {/*        </Checkbox>*/}
                                        {/*    </Col>*/}
                                        {/*</Row>*/}
                                    </>
                                )}

                                <Collapse>
                                    {assetGroup?.type === 'Collection' && (
                                        <Collapse.Panel header={<><UserGroupIcon/> {t('user-group-access','User Group Access')} <HelpPopover text='Add this Group to existing Collections to make them accessible'/></>} key='user_groups'>
                                            <Collapse>
                                                {userGroups?.map((userGroup,i) => {
                                                    const inheritedPermissionIds = values.inherited_permission_ids_by_user_group && values.inherited_permission_ids_by_user_group[userGroup.id]
                                                    const inherited = inheritedPermissionIds?.length

                                                    const overriden = values.asset_group_admins_by_user_group &&
                                                        values.asset_group_admins_by_user_group[userGroup.id] &&
                                                        values.asset_group_admins_by_user_group[userGroup.id].override

                                                    return (
                                                        (<Collapse.Panel
                                                            key={userGroup.id}
                                                            header={
                                                                <Space>
                                                                    {userGroup.name}
                                                                    <Tag>
                                                                        {(values.permission_ids_by_user_group && values.permission_ids_by_user_group[userGroup.id]?.length) || inherited || 0}
                                                                    </Tag>
                                                                </Space>
                                                            }
                                                        >
                                                            {inherited && !overriden ? (
                                                                <>
                                                                    <List
                                                                        header={<strong><LockOutlined/> {t('inherited-from-parent-collection','Inherited from Parent Collection')}:</strong>}
                                                                        size={'small'}
                                                                        bordered
                                                                        dataSource={userGroup.permissions.filter(p => inheritedPermissionIds.indexOf(p.id) != -1)}
                                                                        renderItem={perm => (
                                                                            <List.Item>
                                                                                {perm.name}
                                                                            </List.Item>
                                                                        )}
                                                                    />
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <h4><strong>{t('apply-member-permissions','Apply Member Permissions')}:</strong></h4>

                                                                    <Checkbox.Group
                                                                        name={`permission_ids_by_user_group.${userGroup.id}`}
                                                                        _options={_.compact(userGroup.permissions.map((perm, i) => {
                                                                            return perm.enabled ? {
                                                                                label: `${userGroup.name} - ${perm.name}`,
                                                                                value: perm.id || i
                                                                            } : null
                                                                        }))}
                                                                    >
                                                                        <Space direction={'vertical'} size={1}>
                                                                            {userGroup.permissions.filter(p => p.enabled).map((p, i) => (
                                                                                <Checkbox key={i} value={p.id || i}>
                                                                                    {userGroup.name} - {p.name}
                                                                                    {p.watermark && (
                                                                                        <Tooltip title={t('watermarked','Watermarked')} style={{zIndex:4000}}><Tag style={{margin:'0 .5em'}}><WatermarkIcon/></Tag></Tooltip>
                                                                                    )}
                                                                                </Checkbox>
                                                                            ))}
                                                                        </Space>
                                                                    </Checkbox.Group>

                                                                    <Divider/>

                                                                    <h4><strong>{t('apply-group-manager-permissions','Apply Group Manager Permissions')}:</strong></h4>

                                                                    <Radio.Group
                                                                        name={`asset_group_admins_by_user_group.${userGroup.id}.role_level`}
                                                                    >
                                                                        <Space direction={'vertical'} size={1}>
                                                                            <Radio value={'none'}>None.</Radio>
                                                                            <Radio
                                                                                value={'tagger'}
                                                                                disabled={!userGroup.enable_taggers}
                                                                            >
                                                                                {userGroup.name} - {t('tagging-only','Tagging Only')}
                                                                            </Radio>
                                                                            <Radio
                                                                                value={'library'}
                                                                                disabled={!userGroup.enable_library_managers}
                                                                            >
                                                                                {userGroup.name} - {t('manage-library','Manage Library')}
                                                                            </Radio>
                                                                        </Space>
                                                                    </Radio.Group>
                                                                    <Divider/>
                                                                    <Checkbox
                                                                        name={`asset_group_admins_by_user_group.${userGroup.id}.children_inherit`}
                                                                    >
                                                                        {t('child-collections-inherit-all-parent-permissions','Child Collections inherit all parent permissions')}
                                                                    </Checkbox>
                                                                    <HelpPopover code={'asset-group-access-inherit'}/>
                                                                </>
                                                            )}
                                                            {(inherited || overriden) && (
                                                                <div style={{marginTop:'1em'}}>
                                                                    {/*<Button onClick={() => overridePermissions(userGroup.id)} icon={<EditOutlined/>}>Manual Override</Button>*/}
                                                                    <Checkbox
                                                                        name={`asset_group_admins_by_user_group.${userGroup.id}.override`}
                                                                    >
                                                                        {t('override-inherited-permissions','Override Inherited Permissions')}
                                                                    </Checkbox>
                                                                    <HelpPopover code={'collection-user-group-permissions-override'}/>
                                                                </div>
                                                            )}
                                                        </Collapse.Panel>)
                                                    );
                                                })}
                                            </Collapse>
                                        </Collapse.Panel>
                                    )}

                                    {assetGroup?.type === 'Collection' && currentOrg?.federation && (
                                        <Collapse.Panel id={'enterprise-sharing-panel'} key={'enteprise-sharing'} header={<><CollectionShareIcon/> {t('enterprise-sharing', 'Enterprise Sharing')}</>}>
                                            <VerticalSpace>
                                                <Checkbox name={'enable_public_federation_sharing'}>
                                                    {t('allow-public-federation-sharing','Allow Public Sharing')} <HelpPopover code={'asset-group-form-public-federation-sharing'}/>
                                                </Checkbox>

                                                <Collapse key={'federation-sharing-permissions'}>
                                                    {[ {id: 'default', title: t('all-organizations','All Organizations')}, ...assetGroup.federation.organizations].map(org => (
                                                        <Collapse.Panel key={org.id} header={org.title} id={`enterprise-sharing-${org.id}`}>
                                                            <Checkbox.Group
                                                                name={`federation_sharing_permission_ids_by_organization.${org.id}`}
                                                                _options={_.compact(currentOrg.federation_sharing_permissions.map((perm) => {
                                                                    return perm.enabled ? {
                                                                        label: perm.name,
                                                                        value: perm.id
                                                                    } : null
                                                                }))}
                                                            >
                                                                <Space direction={'vertical'} size={1}>
                                                                    {currentOrg.federation_sharing_permissions.filter(p => p.enabled).map(p => (
                                                                        <Checkbox key={p.id} value={p.id}>
                                                                            {p.name}
                                                                            {p.watermark && (
                                                                                <Tooltip title={t('watermarked','Watermarked')} style={{zIndex:4000}}><Tag style={{margin:'0 .5em'}}><WatermarkIcon/></Tag></Tooltip>
                                                                            )}
                                                                        </Checkbox>
                                                                    ))}
                                                                </Space>
                                                            </Checkbox.Group>
                                                        </Collapse.Panel>
                                                    ))}
                                                </Collapse>
                                            </VerticalSpace>
                                        </Collapse.Panel>
                                    )}

                                    {(assetGroup?.type === 'Lightbox' || assetGroup?.type === 'Collection') && (
                                        <Collapse.Panel header={<span><TableOutlined/> {t('table-of-contents-settings','Table of Contents Settings')} <EnabledDisabled enabled={values.enable_toc}/> <HelpPopover code={'asset-group-form-toc'}/></span>} key={'toc'}>
                                            <Switch name='enable_toc' checkedChildren={t('enabled','Enabled').toProperCase()} unCheckedChildren={t('disabled','Disabled').toProperCase()} />

                                            <FormItem name='poster_image_guid' style={{marginTop:'1em'}}>
                                                <VerticalSpace>
                                                    {values.poster_image_guid && <AssetMention guid={values.poster_image_guid} style={{marginBottom:'1em'}}/>}

                                                    <FloatLabel label={t('poster-image-guid','Poster Image GUID')} name={'poster_image_guid'} value={values.poster_image_guid} description={'e.g. asdf1234'}>
                                                        <Input size={'large'} required name='poster_image_guid' autoComplete={'off'}/>
                                                    </FloatLabel>

                                                    <Checkbox name={'enable_poster_image'}>
                                                        {t('enable-poster-image-banner','Enable Poster Image Banner?')}
                                                    </Checkbox>
                                                </VerticalSpace>
                                            </FormItem>

                                            <Collapse
                                                style={{marginTop:'.5em'}}
                                                items={[{
                                                    key: 'toc_copy',
                                                    label: t('intro-oopy','Intro Copy'),
                                                    children: (
                                                        <Field name="toc_copy">
                                                            {({ field }) => <ReactQuill theme='snow' value={field.value} onChange={field.onChange(field.name)} preserveWhitespace/>}
                                                        </Field>
                                                    )
                                                }]}
                                            />

                                            <FormItem name='toc_contact' style={{marginTop:'1em'}}>
                                                <FloatLabel label={t('contact-emails','Contact Email(s)')} name={'toc_contact'} value={values.toc_contact} description={t('placeholder-lightbox-contact-emails', 'e.g. joe@example.com, jill@example.com')}>
                                                    <Input size={'large'} required name='toc_contact'/>
                                                </FloatLabel>
                                            </FormItem>

                                            <Collapse>
                                                <Collapse.Panel
                                                    header={<>{t('sub','Sub')}-{t(assetGroup.type,assetGroup.type)} {t('sort-order','Sort Order')} <HelpPopover code={'asset-group-children-sort'}/></>}
                                                >
                                                    <ChildrenSortFormItem assetGroup={assetGroup}/>
                                                </Collapse.Panel>
                                            </Collapse>
                                        </Collapse.Panel>
                                    )}

                                    {type === 'Lightbox' && (
                                        <>
                                            <Collapse.Panel
                                                key='collaborators'
                                                header={
                                                    <span>
                                                        <UserOutlined/> {typeName} {t('members','Members')}&nbsp;<Tag>{id ? values.asset_group_memberships_attributes?.length : values.asset_group_invites_attributes?.length}</Tag> <HelpPopover code={'asset-group-form-members'}/>
                                                    </span>
                                                }
                                            >
                                                <FieldArray
                                                    name={'asset_group_memberships_attributes'}
                                                    render={arrayHelpers => {

                                                        return (
                                                            (<List
                                                                bordered
                                                                size={'small'}
                                                                header={<strong>{t('collaborators','Collaborators')}</strong>}
                                                                style={{marginBottom: '1em'}}
                                                            >
                                                                {values.asset_group_memberships_attributes?.map((agm, i) => (
                                                                    <List.Item
                                                                        extra={
                                                                            <div style={{float: 'right'}}>
                                                                                <Space>
                                                                                    <Input
                                                                                        name={`asset_group_memberships_attributes[${i}].id`}
                                                                                        style={{display: 'none'}}
                                                                                    />

                                                                                    <UserAddedAssets agm={agm} editable={assetGroup.user?.id === currentUser?.id && agm.user.id !== assetGroup.user?.id}/>

                                                                                    {agm.user.id !== assetGroup.user?.id && (
                                                                                        <Switch
                                                                                            name={`asset_group_memberships_attributes[${i}].editable`}
                                                                                            checkedChildren={<><EditOutlined/> {t('can-edit','Can Edit')}</>}
                                                                                            unCheckedChildren={<><EyeOutlined/> {t('view-only','View Only')}</>}
                                                                                        />
                                                                                    )}

                                                                                    <Checkbox
                                                                                        name={`asset_group_memberships_attributes[${i}]._destroy`}
                                                                                        rootClassName={'destroy-asset-group-membership'}
                                                                                    >
                                                                                        <Typography.Text type="danger">
                                                                                            <DeleteOutlined/>
                                                                                        </Typography.Text>
                                                                                    </Checkbox>
                                                                                </Space>
                                                                            </div>
                                                                        }
                                                                        key={agm.id}
                                                                    >
                                                                        <User user={agm.user}/> {agm.user.id == assetGroup.user?.id && <Tag>{t('owner','Owner')}</Tag> }
                                                                    </List.Item>
                                                                ))}
                                                                {!values.asset_group_memberships_attributes?.length && (
                                                                    <List.Item>
                                                                        <em>{t('none-yet','None yet.')}</em>
                                                                    </List.Item>
                                                                )}
                                                            </List>)
                                                        );
                                                    }}
                                                />
                                                <FieldArray
                                                    name={'asset_group_invites_attributes'}
                                                    render={arrayHelpers => {

                                                        const createNewInvite = ()=> {
                                                            const value = searchValue
                                                            if(!value) return message.error(t('error-please-enter-emails','Please enter Email(s)'))

                                                            const emails = _.compact(_.uniq(value.split(/;|,/).map(v => v.trim())))
                                                            console.log(values)
                                                            emails.map(email => arrayHelpers.push({email, note: values.invite_note, editable: !!values.invite_editable}))

                                                            setSearchValue('');
                                                        }

                                                        return (<>
                                                            {values.asset_group_invites_attributes?.length && (
                                                                <List
                                                                    bordered
                                                                    size={'small'}
                                                                    header={<><strong>{t('invites','Invites')}</strong> <small style={{float:'right'}}><em>{t('will-be-sent-when-you-click-update','Will be sent when you click update')}.</em></small></>}
                                                                >
                                                                    {values.asset_group_invites_attributes?.map((agi, i) => (
                                                                        <List.Item
                                                                            extra={
                                                                                <div style={{float: 'right'}}>
                                                                                    {agi.id ? (
                                                                                        <Space>
                                                                                            <Input
                                                                                                name={`asset_group_invites_attributes[${i}].id`}
                                                                                                style={{display: 'none'}}
                                                                                            />

                                                                                            <Switch
                                                                                                name={`asset_group_invites_attributes[${i}].editable`}
                                                                                                checkedChildren={<><EditOutlined/> {t('view-edit','View/Edit')}</>}
                                                                                                unCheckedChildren={<><EyeOutlined/> {t('view-only','View Only')}</>}
                                                                                            />

                                                                                            <Checkbox
                                                                                                name={`asset_group_invites_attributes[${i}]._destroy`}
                                                                                                rootClassName={'destroy-asset-group-invite'}
                                                                                            >
                                                                                                <Typography.Text type="danger">
                                                                                                    <DeleteOutlined/>
                                                                                                </Typography.Text>
                                                                                            </Checkbox>
                                                                                        </Space>
                                                                                    ) : (
                                                                                        <Space>
                                                                                            <Switch
                                                                                                name={`asset_group_invites_attributes[${i}].editable`}
                                                                                                checkedChildren={<><EditOutlined/> {t('view-edit','View/Edit')}</>}
                                                                                                unCheckedChildren={<><EyeOutlined/> {t('view-only','View Only')}</>}
                                                                                            />

                                                                                            <Button type={'text'} onClick={() => arrayHelpers.remove(i)}>
                                                                                                <Typography.Text type="danger">
                                                                                                    <DeleteOutlined/>
                                                                                                </Typography.Text>
                                                                                            </Button>
                                                                                        </Space>
                                                                                    )}
                                                                                </div>
                                                                            }
                                                                            key={i}
                                                                        >
                                                                            {agi.email}
                                                                            &nbsp;

                                                                            {agi.note && (
                                                                                <Popover title={t('note','Note')} content={agi.note} zIndex={1033}>
                                                                                    <CommentOutlined/>
                                                                                </Popover>
                                                                            )}

                                                                            &nbsp;
                                                                            <Tag>{t(agi.aasm_state || 'new', agi.aasm_state || 'new')}</Tag>
                                                                        </List.Item>
                                                                    ))}
                                                                </List>
                                                            ) || ''}
                                                            <Card
                                                                title={<><UserAddOutlined/> {t('add','Add')} {typeName} {t('Mmmbers','Members')}</>}
                                                                size={'small'}
                                                                style={{marginTop: '1em'}}
                                                                id={'lightbox-members-invite-form'}
                                                            >
                                                                <VerticalSpace>
                                                                    <div id={'lightbox-members-invite-search'}>
                                                                        <AutoComplete
                                                                            popupMatchSelectWidth={500}
                                                                            defaultActiveFirstOption
                                                                            style={{width: '100%', margin: '.5em 0'}}
                                                                            onSearch={search}
                                                                            options={searchOptions}
                                                                            onSelect={setSearchValue}
                                                                            onChange={setSearchValue}
                                                                            name={'invite_input'}
                                                                            value={searchValue}
                                                                        >
                                                                            <AntInput.Search placeholder={t('placeholder-search-members-by-email',"Enter Email Address(s), separated w/ commas or semicolons...")} autoComplete={'off'}/>
                                                                        </AutoComplete>
                                                                    </div>

                                                                    <Input.TextArea
                                                                        placeholder={t('placeholder-add-a-note','Add a Note...')}
                                                                        name={'invite_note'}
                                                                    />

                                                                    <Switch
                                                                        name={'invite_editable'}
                                                                        checkedChildren={<><EditOutlined/> {t('view-edit','View/Edit')}</>}
                                                                        unCheckedChildren={<><EyeOutlined/> {t('view-only','View Only')}</>}
                                                                    />

                                                                    <div style={{display:'flex', alignItems:'right'}}>
                                                                        <Button icon={<UserAddOutlined/>} onClick={createNewInvite} disabled={searchValue == ''} style={{marginTop:'.5em', marginLeft:'auto'}} type={'primary'}>
                                                                            {t('button-add-invite','Add Invite')}
                                                                        </Button>
                                                                    </div>
                                                                </VerticalSpace>
                                                            </Card>
                                                        </>);
                                                    }}
                                                />
                                            </Collapse.Panel>

                                            <Collapse.Panel key='permissions' header={<><LockOutlined/> {typeName} {t('member Permissions','Member Permissions')} <EnabledDisabled enabled={values?.override_permissions}/> <HelpPopover code={'asset-group-form-member-permissions'}/></>}>
                                                <Checkbox name={'override_permissions'}>{t('override-member-restrictions','Override Member Restrictions')} <HelpPopover code={'lightbox-override-permissions'}/></Checkbox>

                                                <p>
                                                    <small>
                                                        <em>
                                                            {t('lightbox-invited-members-permissions-note-1','By default, invited Lightbox members will be restricted to their existing access to the files in this Lightbox. If they have no existing access to the files, they will see a small watermarked version fo the file.')}
                                                            <br/>
                                                            {t('lightbox-invited-members-permissions-note-2','You can confer your access to the files by overriding the member restrictions.')}
                                                        </em>
                                                    </small>
                                                </p>

                                                {values?.override_permissions && (
                                                    <>
                                                        <Card title={<strong>{t('view-download-access','View/Download Access')}</strong>} size={'small'} extra={<em>{t('you-can-confer-your-permissions','You can confer your permissions.')}</em>} style={{marginTop:'1em'}}>

                                                            <h4>{t('image-and-video-files','Image and Video files')}</h4>
                                                            <Checkbox name={'watermark_all'} disabled={values.image_and_video_permission === 'original'}> {t('watermark-all-image-and-videos','Watermark All Image and Videos')}</Checkbox>
                                                            <br/>
                                                            <br/>
                                                            <Select name={'image_and_video_permission'} style={{width:'100%'}} placeholder={t('placeholder-select','Select...')} onChange={changeImageVideoPermission}>
                                                                <Select.Option value={'original'}>{t('images-and-video','Images and Video')} - {t('view-download-original-file','View/Download Original File')}</Select.Option>
                                                                <Select.Option value={'full'}>{t('images-and-video','Images and Video')} - {t('view-download-full-resolution','View/Download Full Resolution')}</Select.Option>
                                                                <Select.Option value={'medium'}>{t('images-and-video','Images and Video')} - {t('view-download-medium-resolution','View/Download Medium Resolution')}</Select.Option>
                                                                <Select.Option value={'small'}>{t('images-and-video','Images and Video')} - {t('view-Download-low-resolution','View/Download Low Resolution')}</Select.Option>
                                                                {/*<Select.Option value={'none'}>No Download</Select.Option>*/}
                                                            </Select>
                                                            <br/>
                                                            <Divider/>
                                                            <strong>{t('other-file-types','Other file types')}</strong>
                                                            <br/>
                                                            <Select name={'other_permission'} style={{width:'100%'}} placeholder={t('placeholder-Select','Select...')}>
                                                                <Select.Option value={'original'}>{t('original-file','Original File')}</Select.Option>
                                                                <Select.Option value={'none'}>{t('no-download','No Download')}</Select.Option>
                                                            </Select>
                                                        </Card>

                                                        <Card title={<strong>{t('allow-lightbox-tagging','Allow Lightbox Tagging')}</strong>} size={'small'} style={{marginTop:'1em'}}>
                                                            <Space direction={'vertical'}>
                                                                {/*<Checkbox name={'allow_comments'}>Comment on Lightboxes and Assets</Checkbox>*/}
                                                                <Checkbox name={'allow_tagging'}>{t('update-keywords','Update keywords')}</Checkbox>
                                                                <Checkbox name={'allow_edits'}>{t('add-or-modify-asset-captions','Add or modify asset captions')}</Checkbox>
                                                            </Space>
                                                        </Card>
                                                    </>
                                                )}

                                                {/*<Card title={<strong>Allow Lightbox Sharing</strong>} size={'small'} style={{marginTop:'1em'}}>*/}
                                                {/*    <Checkbox name={'allow_invites'}>Invite Others</Checkbox>*/}
                                                {/*</Card>*/}
                                            </Collapse.Panel>

                                            {/*{typeName !== 'Project' && (*/}
                                            {/*    <Collapse.Panel key='approvals' header={`Forward Approved Files to another ${typeName}`}>*/}
                                            {/*        Coming soon!*/}
                                            {/*    </Collapse.Panel>*/}
                                            {/*)}*/}

                                            {ability.can('create', 'ShareLink') && type === 'Lightbox' && subType !== 'project' && assetGroup?.id && (
                                                <Collapse.Panel
                                                    key='sharing'
                                                    header={
                                                        <span>
                                                            <LinkOutlined/> <span>{t('share-links','Share Links')}</span>
                                                            &nbsp;{!!(shareLinksCount || assetGroup?.share_links_count) && <Tag>{shareLinksCount || assetGroup.share_links_count}</Tag>}
                                                            <HelpPopover code={'asset-group-form-share-links'}/>
                                                        </span>
                                                    }
                                                >
                                                    <ShareLinks assetGroup={assetGroup} setCount={setShareLinksCount}/>
                                                </Collapse.Panel>
                                            )}

                                            {ability.can('create', 'Share') && type === 'Lightbox' && subType !== 'project' && assetGroup?.id && (
                                                <Collapse.Panel
                                                    key='share'
                                                    header={
                                                        <span>
                                                            <ShareIcon/> {t('quick-shares','Quick Shares')}
                                                        </span>
                                                    }
                                                >
                                                    <SharesTable assetGroup={assetGroup}/>
                                                </Collapse.Panel>
                                            )}

                                            {canEnableUploads && (
                                                <Collapse.Panel
                                                    header={<span><CloudUploadOutlined/> {assetGroup.sub_type === 'project' && t('lightbox','Lightbox')} {t('uploads','Uploads')} <EnabledDisabled enabled={values?.enable_contribution}/> <HelpPopover code={'asset-group-form-uploads'}/></span>}
                                                    key="contributors"
                                                >
                                                    <Checkbox name={'enable_contribution'} style={{marginBottom:'1em'}}>{t('enable-contributions-for-all-lightbox-members','Enable Contributions for all Lightbox Members')}</Checkbox>

                                                    <ContributionDetailsForm
                                                        disabled={!values?.enable_contribution}
                                                        fieldName={'contribution_attributes'}
                                                        noFeatured={assetGroup?.type == 'Lightbox'}
                                                    />

                                                </Collapse.Panel>
                                            )}
                                        </>
                                    )}

                                    {(type === 'Collection') && (
                                        <Collapse.Panel header={<><EyeInvisibleOutlined/> {t('embargo','Embargo')} <EnabledDisabled enabled={values.enable_embargo}/> <HelpPopover code={'asset-group-form-embargo'}/></>} key={'embargo'}>
                                            <VerticalSpace size={'large'}>
                                                <Switch name='enable_embargo' checkedChildren={t('enabled','Enabled')} unCheckedChildren={t('disabled','Disabled')} />

                                                <FormItem name='embargo_ends_at' label={t('end-embargo-at','End Embargo At:')}>
                                                    <DatePicker
                                                        showTime
                                                        name={'embargo_ends_at'}
                                                        disabled={!values.enable_embargo}
                                                        allowClear
                                                        format={d => day(d.toString()).tz(timezone).format('MM/DD/YYYY HH:mm:ss z')}
                                                    />
                                                </FormItem>

                                            </VerticalSpace>
                                        </Collapse.Panel>
                                    )}
                                </Collapse>
                            </Form>
                        </Skeleton>
                    </Drawer>)
                );
            }}
        </Formik>)
    );
}

const ShareLinks = ({assetGroup, setCount})=> {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(true)
    const [shareLinks, setShareLinks] = useState([])

    const route = `/api/asset_groups/${assetGroup.asset_group_id}/share_links`

    const load = ()=>{
        api(route).then(res => {
            setLoading(false)
            setShareLinks(res.data)
            setCount(res.data.length)
        })
    }

    useEffect(()=>{
        if(!assetGroup) return
        load()

    }, [assetGroup?.asset_group_id])

    const [creating, setCreating] = useState()
    const createNew = ()=>{
        setCreating(true)
        api.post(route).then(res => {
            setCreating(false)
            message.success(t('message-share-link-created','Share Link Created!'))
            setShareLinks([res.data, ...shareLinks])
            setCount(shareLinks.length + 1)
        })
    }

    return (
        <Space direction={'vertical'} style={{width:'100%'}}>
            <Button loading={creating} icon={<PlusOutlined/>} onClick={createNew} ghost type={'primary'}>{t('button-create-new-share-link','Create New Share Link')}</Button>
            <List
                size={'small'}
                bordered
                dataSource={shareLinks}
                locale={{emptyText:t('none-yet','None yet.')}}
                loading={loading}
                renderItem={sl => <ShareLinkItem sl={sl} onDestroy={load}/>}
            />
        </Space>
    )
}

const ShareLinkItem = ({sl, onDestroy}) => {
    const {t} = useTranslation();
    const timezone = useTimezone()

    const [attrs, setAttrs] = useState(sl)

    useEffect(()=>{
        setAttrs(sl)
    }, [sl.id])

    const route = `/api/asset_groups/${sl.asset_group_id}/share_links/${sl.id}`

    const update = field => {
        return (value)=>{
            const share_link = {}
            share_link[field] = value

            attrs[field] = value;
            setAttrs({...attrs})

            api.put(route, {share_link}).then(res => {
                setAttrs(res.data)
                message.success(t('message-share-link-updated','Share Link Updated.'))
            })
        }
    }

    const destroy = ()=>{
        api.delete(route).then(res => {
            message.success(t('message-share-link-deleted.','Share Link Deleted.'))
            onDestroy()
        })
    }

    const disableWatermark = attrs.image_and_video_permission === 'original'

    return (
        <List.Item>
            <List.Item.Meta
                title={
                    <Space direction={'vertical'} style={{width:'100%'}}>
                        <Row>
                            <Col>
                                <Space>
                                    <Switch checkedChildren={t('enabled','Enabled')?.toProperCase()} unCheckedChildren={t('disabled','Disabled')?.toProperCase()} name={'enabled'} checked={attrs.enabled} onChange={update('enabled')}/>
                                    <Typography.Text code copyable={{text: sl.url, tooltips:t('copy-link','Copy Link')}} className={'share-link-url'}>{sl.url}</Typography.Text>
                                </Space>
                            </Col>
                            <Col style={{marginLeft:'auto'}}>
                                <Popconfirm title={t('confirm-delete-share-link','Delete Share Link?')} onConfirm={destroy} zIndex={10_000}>
                                    <Button icon={<DeleteOutlined/>} danger ghost size={'small'}/>
                                </Popconfirm>
                            </Col>
                        </Row>

                        <Row>
                            <Col flex={'none'} gutter={[8,8]}>
                                <Switch checkedChildren={t('watermarked','Watermarked')}
                                        unCheckedChildren={t('unwatermarked','Unwatermarked')}
                                        name={'watermark_all'}
                                        checked={!disableWatermark && attrs.watermark_all}
                                        onChange={update('watermark_all')}
                                        disabled={disableWatermark}
                                />
                            </Col>

                            <Col flex={'auto'} gutter={[8,8]} style={isMobile() && {marginTop:'.5em'}}>
                                <Select name={'image_and_video_permission'} value={attrs.image_and_video_permission} placeholder={t('placeholder-image-and-video-download','Image and Video Download...')}
                                        onChange={update('image_and_video_permission')}
                                        style={isMobile() ? {margin:'.5em 0'} : {margin:'0 .5em'}}
                                >
                                    <Select.Option value={'original'}>{t('images-and-video','Images and Video')} - {t('original-file','Original File')}</Select.Option>
                                    <Select.Option value={'full'}>{t('images-and-video','Images and Video')} - {t('full-resolution','Full Resolution')}</Select.Option>
                                    <Select.Option value={'medium'}>{t('images-and-video','Images and Video')} - {t('medium-resolution','Medium Resolution')}</Select.Option>
                                    <Select.Option value={'small'}>{t('images-and-video','Images and Video')} - {t('low-resolution','Low Resolution')}</Select.Option>
                                    {/*<Select.Option value={'none'}>No Download</Select.Option>*/}
                                </Select>
                                <Select name={'other_permission'} placeholder={t('placeholder-other-file-types','Other File Types...')}
                                        value={attrs.other_permission}
                                        onChange={update('other_permission')}
                                >
                                    <Select.Option value={'original'}>{t('other-files','Other Files')} - {t('original-file','Original File')}</Select.Option>
                                    <Select.Option value={'none'}>{t('other-files','Other Files')} - {t('no-download','No Download')}</Select.Option>
                                </Select>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Space>
                                    <Switch checkedChildren={t('expires','Expires')} unCheckedChildren={t('no-expiration','No Expiration')} name={'expires'} checked={attrs.expires} onChange={update('expires')}/>
                                    <DatePicker
                                        showTime
                                        name={'expires_at'}
                                        value={attrs.expires_at ? day(attrs.expires_at) : day().add(7,'days')}
                                        onChange={update('expires_at')}
                                        disabled={!attrs.expires}
                                        allowClear={false}
                                        format={d => day(d.toString()).tz(timezone).format('MM/DD/YYYY HH:mm:ss z')}
                                    />

                                    {attrs.expired && <Tag color={'red'} icon={<ClockCircleOutlined/>}>{t('expired','Expired')}</Tag>}
                                </Space>
                            </Col>
                        </Row>

                        <Row>
                            <Col flex={'none'}>
                                <CommentOutlined style={{marginRight:'.5em'}}/>
                            </Col>
                            <Col flex={'auto'}>
                                <Typography.Paragraph editable={{onChange: update('note')}}>{attrs.note || <em>{t('add-an-internal-note','Add an internal note...')}</em>}</Typography.Paragraph>
                            </Col>
                        </Row>
                    </Space>
                }
                description={
                    <small>
                        <em>{t('created','Created')} <TimeAgo date={sl.created_at}/> {t('by','by')} <User user={sl.user}/></em>
                    </small>
                }
            />
        </List.Item>
    );
}

const UserAddedAssets = ({agm, editable})=> {
    const {t} = useTranslation();
    const [loading, setLoading] = useState()
    const [count, setCount] = useState(agm.assets_count)

    const apply = ()=> {
        setLoading(true)

        api.post(`/api/lightboxes/apply_asset_group_membership_assets?id=${agm.id}`).then(res => {
            setCount(0)
            setLoading(false)
            message.success('Assets applied!')
        })
    }

    const remove = ()=> {
        setLoading(true)

        api.post(`/api/lightboxes/remove_asset_group_membership_assets?id=${agm.id}`).then(res => {
            setCount(0)
            setLoading(false)
            message.success('Assets removed!')
        })
    }

    if(count === 0) {
        return (
            <Tooltip
                zIndex={10000}
                title={t('no-added-assets','No Added Assets')}
            >
                <Tag icon={<FileAddOutlined/>}>0</Tag>
            </Tooltip>
        )
    }

    return (
        <Popover
            title={t('added-assets','Added Assets')}
            placement={'left'}
            zIndex={10000}
            content={
                <VerticalSpace>
                    <p>
                        {t('this-member-has-added-assets','This member has added {count} Asset(s).', {count})}
                    </p>

                    {editable && count > 0 && (
                        <Space>
                            <Popconfirm title={t('confirm-approve-assets-for-sharing','Approve these Assets for sharing?')} onConfirm={apply} zIndex={10001}>
                                <Button type='primary' icon={<CheckOutlined/>} loading={loading}>{t('button-approve-for-sharing','Approve for Sharing')}</Button>
                            </Popconfirm>

                            <Popconfirm title={t('confirm-remove-assets','Remove these Assets?')} onConfirm={remove} zIndex={10001}>
                                <Button danger icon={<DeleteOutlined/>} loading={loading}>{t('button-remove','Remove')}</Button>
                            </Popconfirm>
                        </Space>
                    )}
                </VerticalSpace>
            }
        >
            <Tag icon={loading ? <LoadingOutlined/> : <FileAddOutlined/>} color={'blue'}>{count}</Tag>
        </Popover>
    )
}

const ChildrenSortFormItem = ({assetGroup}) => {
    const {t} = useTranslation();
    return (
        <FormItem name='children_sort_order' style={{marginTop:'.5em'}}>
            <VerticalSpace>
                <strong>{t('name-of','Name of')} {t(assetGroup.type,assetGroup.type)}:</strong>
                <Radio.Group name={'children_sort_order'}>
                    <Radio value={'name-asc'}> {t('A-Z','A-Z')} </Radio>
                    <Radio value={'name-desc'}> {t('Z-A','Z-A')} </Radio>
                </Radio.Group>
                <br/>

                <strong>{t(assetGroup.type,assetGroup.type)} {t('created-date','Created Date')}:</strong>
                <Radio.Group name={'children_sort_order'}>
                    <Radio value={'created_at-desc'}> {t('newest-first','Newest First')} </Radio>
                    <Radio value={'created_at-asc'}> {t('oldest-first','Oldest First')} </Radio>
                </Radio.Group>
                <br/>

                <strong>{t('modification-date','Modification Date')}:</strong>
                <Radio.Group name={'children_sort_order'}>
                    <Radio value={'updated_at-desc'}> {t('newest-first','Newest First')} </Radio>
                    <Radio value={'updated_at-asc'}> {t('oldest-first','Oldest First')} </Radio>
                </Radio.Group>
                <br/>

                <strong>{t('Size of','Size of')} {t(assetGroup.type,assetGroup.type)}:</strong>
                <Radio.Group name={'children_sort_order'}>
                    <Radio value={'size-desc'}> {t('largest-first','Largest First')} </Radio>
                    <Radio value={'size-asc'}> {t('smallest-first','Smallest First')} </Radio>
                </Radio.Group>
                <br/>

                {(assetGroup.type !== 'StorageFolder') && (
                    <Radio.Group name={'children_sort_order'}>
                        <Radio value={'custom'}>
                            <BulbOutlined/> {t('custom-drag-and-drop-to-reorder)','Custom (Drag-and-Drop to Reorder)')}
                        </Radio>
                    </Radio.Group>
                )}
            </VerticalSpace>
        </FormItem>
    )
}

export {ChildrenSortFormItem}