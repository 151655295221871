import {OrgLink} from "../helpers/OrgNavLink";
import {Card, Tooltip, message} from "antd";
import {CollectionIcon} from "../helpers/icons";
import InfoCircleOutlined from "@ant-design/icons/lib/icons/InfoCircleOutlined";
import React from "react";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import {useDrag, useDrop} from "react-dnd";
import {useAssetGroupDispatch} from "../../contexts/AssetGroupContext";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useCurrentOrg from "../helpers/useCurrentOrg";

export default ({c, size = '100%', draggable, onReorder}) => {
    const {t} = useTranslation();
    const src = c.poster_image?.cover_url
    const alt = c.poster_image?.alt_text || c.poster_image?.description

    const [{canDrop, isOver}, drop] = useDrop({
        accept: 'collection',
        drop: ({collection: dragCollection}) => {
            const key = 'reordering-loading'
            message.loading({content: 'Reordering...', key})

            onReorder(c, dragCollection)

            api.post(`/api/asset_groups/${c.parent_id}/reorder_children`, {collection_id: dragCollection.id, before_collection_id: c.id}).then(res => {
                message.destroy(key)
                message.success(t('sub-collections-reordered','Sub-Collections Reordered!'))
            })
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
        canDrop: ()=> draggable
    });

    const [{ opacity }, drag, preview] = useDrag({
        item: { collection: c, type: 'collection' },
        canDrag: ()=> draggable,
        collect: monitor => ({
            opacity: monitor.isDragging() ? 0.4 : 1,
        }),
    });

    const isActive = canDrop && isOver;

    // If `shareCode` present, use Folder path
    const { shareCode } = useParams();

    const currentOrg = useCurrentOrg()

    let path;
    if(shareCode) {
        path =  `/share-links/${shareCode}/${c.path_slugs[c.path_slugs.length-1]}`
    } else if(c.organization_id !== currentOrg.id) {
        path = `/explore/shared-libraries/${c.organization_slug}/${c.path_slugs[c.path_slugs.length-1]}`
    } else {
        path = `/explore/collections/${c.slug}`
    }

    const assetGroupDispatch = useAssetGroupDispatch()
    const onClick = ()=> {
        assetGroupDispatch({type: 'setCurrentAssetGroup', assetGroup: c});
    }

    return (
        <div ref={drop}>
            {isActive && <div className={'asset-reorder-indicator'} style={{zIndex:1000}}/>}

            <OrgLink to={path} className={'collection-card'} id={`collection-${c.id}-button`} onClick={onClick}>
                <Card
                    ref={drag}
                    size={'small'}
                    hoverable
                    style={{height:'100%', opacity}}
                    bodyStyle={{display:'none'}}
                    cover={
                        <>
                            <div style={{position:'absolute', top:-1, left: -1, width:'calc(100% + 2px)', padding:'.5em', backgroundColor: 'rgba(0, 0, 0, .5)', color: '#fff', borderTopRightRadius:10, borderTopLeftRadius:10}}>
                                <CollectionIcon/> {c.name}

                                {c.description && (
                                    <Tooltip title={c.description} placement={'right'} trigger={['hover','click']}>
                                        <InfoCircleOutlined style={{marginLeft:'.5em'}}/>
                                    </Tooltip>
                                )}
                            </div>

                            {src ? (
                                <img src={src} style={{objectFit: 'cover', height: isMobile() ? '60vw' : '16vw', borderRadius:10}} alt={alt}/>
                            ) : (
                                <div style={{height: isMobile() ? '60vw' : '16vw'}}></div>
                            )}
                        </>
                    }
                />
            </OrgLink>
        </div>
    )
}