import React, {useState} from "react";

import {Button, Col, Collapse, Row, Space, Tag, Tooltip} from "antd";
const { Panel } = Collapse;
import {CheckCircleTwoTone, CloseCircleTwoTone, PlusOutlined} from "@ant-design/icons";

import {useFormikContext, FieldArray} from 'formik';
import PermissionSelect from "./PermissionSelect";
import EnabledDisabled from "../helpers/EnabledDisabled";
import {WatermarkIcon} from "@/components/helpers/icons";
import {useTranslation} from "react-i18next";

export default ({fieldName='permissions_attributes', name=null})=> {
    const {t} = useTranslation();
    const { values } = useFormikContext();

    const [activeKey, setActiveKey] = useState();

    const onChange = (key) => {
       setActiveKey(key);
    }

    const addNew = (arrayHelpers) => {
        return () => {
            const key = values[fieldName].length.toString();
            arrayHelpers.push({ enabled: true });
            setActiveKey(key);
        }
    }

    return (
        <FieldArray
            name={fieldName}
            render={(arrayHelpers) => (
                <>
                    <Collapse activeKey={activeKey} onChange={onChange} style={{marginBottom:'1em'}}>
                        {values[fieldName]?.map((perm,i) => (
                            <Panel
                                key={i}
                                header={
                                    <>
                                        {name || values.name} -&nbsp;
                                        {perm.name || <em>{t('adding-custom-permission', 'Adding Custom Permission')}...</em>}
                                        {perm.watermark && (
                                            <Tooltip title={t('watermarked','Watermarked')} style={{zIndex:4000}}><Tag style={{margin:'0 .5em'}}><WatermarkIcon style={{marginRight:0}}/></Tag></Tooltip>
                                        )}
                                        <EnabledDisabled enabled={perm.enabled}/>
                                        <div style={{float:'right'}}>
                                            <Tag>{perm.asset_group_permissions_count} Uses</Tag>
                                        </div>
                                    </>
                                }
                            >
                                <PermissionSelect i={i} preset={perm.preset} fieldName={fieldName}/>
                            </Panel>
                        ))}
                    </Collapse>

                    <Button onClick={addNew(arrayHelpers)}><PlusOutlined /> {t('add-another-custom-permission','Add Another Custom Permission')}</Button>
                </>
            )}
        />
    );
}

