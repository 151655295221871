import React, {useContext, useEffect, useRef, useState} from "react";

import _ from 'lodash';

import {
    Card,
    Button,
    message,
    Upload,
    Divider,
    Space,
    Tooltip,
    Popconfirm,
    Collapse,
    Typography,
    Alert,
    Descriptions, Skeleton, Table, Modal, Switch as AntSwitch, Row, Col, Flex
} from 'antd';

import api from "../api";
import {AppContext} from "../../contexts/AppContext";
import {FieldArray, Formik, useFormikContext} from "formik";
import * as Yup from "yup";
import {Form, FormItem, Input, SubmitButton, Select, Checkbox, Radio, Switch} from "formik-antd";
import {
    PoweroffOutlined,
    LoadingOutlined,
    PlusOutlined,
    GlobalOutlined,
    UploadOutlined,
    MinusOutlined, DeleteOutlined, CheckOutlined, MenuOutlined, SaveOutlined, CloseCircleFilled
} from "@ant-design/icons";
import {SessionContext} from "../../contexts/SessionContext";
import FloatLabel from "../helpers/FloatLabel";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import useCurrentOrg from "../helpers/useCurrentOrg";
import {AssetMention} from "../widgets/CommentThread";
import HelpPopover from "../HelpPopover";
import EnabledDisabled from "../helpers/EnabledDisabled";
import OrgNavLink from "../helpers/OrgNavLink";
import {SketchPicker, TwitterPicker} from "react-color";
import VerticalSpace from "../helpers/VerticalSpace";
import {TimezoneSelect} from "../registration/Account";
import AssetGroupChooser from "./AssetGroupChooser";

export default () => {
    const {t} = useTranslation();
    setTitle(t('site-settings','Site Settings'))

    const {state} = useContext(AppContext);
    const {dispatch} = useContext(SessionContext);

    const currentOrg = useCurrentOrg();

    const [attrs, setAttrs] = useState()

    useEffect(()=>{
        if(!currentOrg) return

        api(`/api/organizations/${currentOrg.id}/edit`).then(res => {
            setAttrs(res.data)
        })
    }, [currentOrg?.id])

    const [logoLoading, setLogoLoading] = useState(false);
    const [logoUrl, setLogoUrl] = useState(currentOrg?.logo_url);

    const [largeLogoLoading, setLargeLogoLoading] = useState(false);
    const [largeLogoUrl, setLargeLogoUrl] = useState(currentOrg?.large_logo_url);

    function beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error(t('error-only-upload-jpg-png','You can only upload JPG/PNG file.'));
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error(t('error-image-must-smaller-2MB','Image must smaller than 2MB.'));
        }
        return isJpgOrPng && isLt2M;
    }

    const handleLogoChange = info => {
        if (info.file.status === 'uploading') {
            setLogoLoading(true)
            return;
        }
        if (info.file.status === 'done') {
            setLogoLoading(false);
            setLogoUrl(info.file.response.logo_url);
            dispatch({
                type:'org_updated',
                org: info.file.response,
            });
        }
    };

    const handleLargeLogoChange = info => {
        if (info.file.status === 'uploading') {
            setLargeLogoLoading(true)
            return;
        }
        if (info.file.status === 'done') {
            setLargeLogoLoading(false);
            setLargeLogoUrl(info.file.response.large_logo_url);
            dispatch({
                type:'org_updated',
                org: info.file.response,
            });
        }
    };

    const clickRemoveLogo = (size)=> {
        const data = {organization:{}}
        data.organization[`remove_${size}`] = true
        api.put(`/api/organizations/${currentOrg?.id}`, data).then(res => {
            size == 'logo' ? setLogoUrl(null) : setLargeLogoUrl(null)
            message.success(t('message-organization-updated','Organization updated.'))
            dispatch({
                type:'org_updated',
                org: res.data,
            });
        })
    }

    const logoUploadButton = (
        <div>
            {logoLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div className="ant-upload-text">{t('upload','Upload')}</div>
        </div>
    );

    const largeLogoUploadButton = (
        <div>
            <Button icon={<UploadOutlined/>} loading={largeLogoLoading}>{t('upload','Upload')}</Button>
        </div>
    );

    return (
        (<Card
            title={ <><GlobalOutlined/> {t('organization-settings','Organization Settings')} <div style={{float:'right', color:'grey'}}>ID: <Typography.Text copyable>{currentOrg?.id}</Typography.Text></div></> }
            style={{width: isMobile() ? '100%' : 700, margin:'1em auto', backgroundColor:'white'}}
        >
            <Skeleton loading={!attrs}>
                {attrs && (
                    <Formik
                        initialValues={
                            _.pick(attrs,['title', 'public_headline', 'public_text',
                                'public_banner_asset_guids', 'login_background_asset_guid', 'lightbox_upload_role_level', 'toc_default_enabled', 'toc_default_enable_poster_image', 
                                'enable_product_info', 'enable_artwork_or_object', 'enable_truncated_date_writing',
                                'enable_sso', 'hide_password_login', 'sso_type', 'encrypt_saml', 'saml_email_attribute_name', 'openid_client_id', 'openid_client_secret', 'openid_host', 'saml_idp_url', 'view_settings', 'default_asset_modal_layout',
                                'cas_server_url', 'cas_login_url', 'sso_groups_field',
                                'enable_rekognition', 'enable_general_member_auto_tags', 'omit_auto_tag_results', 'auto_tag_confidence_threshold', 'enable_nsfw_detection', 'default_timezone',
                                'enable_membership_requests', 'auto_approve_enabled', 'auto_approve_domain', 'auto_approve_group_id', 'auto_approve_collection_id', 'auto_approve_welcome_message', 'download_filename_format',
                                'download_statement', 'enable_download_statement', 'header_background_color', 'header_text_color', 'header_highlight_color', 'access_request_notification_emails',
                                'access_request_terms', 'contact_email', 'contact_address', 'contact_phone', 'enable_access_requests', 'company_name', 'overwrite_exif_date',
                                'default_sort_order', 'default_landing_page', 'enable_transcription', 'share_download_role_level', 'enable_face_tagging', 'enable_face_training_mode', 'medium_download_size',
                                'sidebar_settings', 'inactivity_timeout_minutes', 'alt_text_generation_prompt', 'alt_text_generation_mode', 'rights_package_expiration_notify_days',
                                'quick_info_settings', 'enable_help_menu_link', 'help_menu_link_text', 'help_menu_link_url', 'federation_sharing_permissions_attributes'
                            ])
                        }
                        onSubmit={(values, actions) => {
                            api({
                                method:'patch',
                                url: `/api/organizations/${currentOrg?.id}`,
                                data: { organization: values }
                            }).then((res)=>{
                                dispatch({
                                    type:'org_updated',
                                    org: res.data,
                                });

                                setAttrs(res.data)

                                actions.setSubmitting(false)

                                message.success(t('message-organization-settings-updated','Organization settings have been updated!'))

                            }).catch((error)=>{
                                console.error(error.response?.data)
                                message.error(`Error: ${JSON.stringify(error.response?.data)}`)
                                actions.setSubmitting(false)
                            })
                        }}
                        validationSchema={
                            Yup.object({
                                title: Yup.string().required(t('required','Required')),
                                auto_approve_domain: Yup.string().trim().nullable().matches(/^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/)
                            })
                        }
                    >
                        {({isSubmitting, values, setFieldValue, initialValues}) => {

                            const [verifyingSaml, setVerifyingSaml] = useState()
                            const verifySaml = ()=> {
                                setVerifyingSaml(true)
                                api.post(`/api/organizations/${currentOrg.id}/verify_saml`, {url: values.saml_idp_url}).then(res => {
                                    setVerifyingSaml(false)
                                    if(res.data.error) {
                                        message.error(`Error: ${JSON.stringify(res.data.error)}`)
                                    } else {
                                        message.success(t('message-saml-valid','SAML valid!'))
                                    }
                                })
                            }

                            const resetColors = ()=>{
                                ['header_text_color', 'header_background_color', 'header_highlight_color'].map(f => {
                                    setFieldValue(f, initialValues[f])
                                    currentOrg[f] = initialValues[f]
                                })

                                dispatch({
                                    type:'org_updated',
                                    org: {...currentOrg},
                                });
                            }

                            // <Button disabled={!values.saml_idp_url} loading={verifyingSaml} onClick={verifySaml}>Verify</Button>

                            const locale = getLocale()

                            const getSamlUrl = action => {
                                return `https://${currentOrg?.domain?.length ? currentOrg.domain : location.host}/auth/saml${action ? `/${action}` : ''}${!currentOrg?.domain?.length ? `?organization_id=${currentOrg?.id}` : ''}`
                            }


                            return (
                                <Form layout='vertical'>
                                    <FormItem label={t('title','Title')} name="title" required showValidateSuccess>
                                        <Input
                                            required
                                            name="title" placeholder={t("placeholder-organization-title","Title...")}/>
                                    </FormItem>

                                    <FormItem label={t('default-timezone','Default Timezone')} name="default_timezone">
                                        <TimezoneSelect
                                            SelectComponent={Select}
                                            name={'default_timezone'}
                                            locale={locale}
                                        />
                                    </FormItem>

                                    <Space direction={'vertical'} style={{width: '100%'}}>
                                        <Collapse>
                                            <Collapse.Panel key={'logos'}
                                                            header={<>Logos <HelpPopover code={'site-settings-logos'}/></>}>
                                                <label>
                                                    {t('header-logo','Header Logo')}
                                                    {logoUrl && <Popconfirm title={t('confirm-remove-header-logo','Remove header logo?')}
                                                                            onConfirm={() => clickRemoveLogo('logo')}><Button
                                                        icon={<DeleteOutlined/>} type={'text'}/></Popconfirm>}
                                                </label>
                                                <Upload
                                                    name="organization[logo]"
                                                    listType="picture-card"
                                                    className="avatar-uploader"
                                                    showUploadList={false}
                                                    action={`/api/organizations/${currentOrg?.id}`}
                                                    method='put'
                                                    beforeUpload={beforeUpload}
                                                    onChange={handleLogoChange}
                                                    headers={{'Authorization': `Bearer ${state.jwt}`}}
                                                    style={{backgroundColor: '#000'}}
                                                >
                                                    {logoUrl ?
                                                        <Tooltip title={t('tooltip-click-to-change','Click to change')}><img src={logoUrl} alt="avatar"
                                                                                                style={{
                                                                                                    width: '100%',
                                                                                                    backgroundColor: '#000'
                                                                                                }}/></Tooltip> : logoUploadButton}
                                                </Upload>

                                                <Divider/>

                                                <label style={{marginBottom: '1em', display: 'block'}}>
                                                    {t('large-logo','Large Logo')}
                                                    {largeLogoUrl && <Popconfirm title={t('confirm-remove-large-logo','Remove large logo?')}
                                                                                 onConfirm={() => clickRemoveLogo('large_logo')}><Button
                                                        icon={<DeleteOutlined/>} type={'text'}/></Popconfirm>}
                                                </label>
                                                <Upload
                                                    name="organization[large_logo]"
                                                    listType="picture"
                                                    className="avatar-uploader"
                                                    showUploadList={false}
                                                    action={`/api/organizations/${currentOrg?.id}`}
                                                    method='put'
                                                    beforeUpload={beforeUpload}
                                                    onChange={handleLargeLogoChange}
                                                    headers={{'Authorization': `Bearer ${state.jwt}`}}
                                                >
                                                    {largeLogoUrl ?
                                                        <Tooltip title={t('tooltip-click-to-change','Click to change')}><img src={largeLogoUrl} alt="avatar"
                                                                                                style={{
                                                                                                    width: '100%',
                                                                                                    cursor: 'pointer'
                                                                                                }}/></Tooltip> : largeLogoUploadButton}
                                                </Upload>
                                            </Collapse.Panel>

                                            <Collapse.Panel
                                                key={'branding'}
                                                header={<>{t('branding','Branding')} <HelpPopover code={'site-settings-branding'}/></>}
                                            >
                                                <VerticalSpace>
                                                    <Checkbox name={'enable_help_menu_link'}>{t('enable-help-menu-link','Enable Help Menu Link')}</Checkbox>

                                                    {values.enable_help_menu_link && (
                                                        <VerticalSpace>
                                                            <FormItem label={t('help-menu-link-text','Help Menu Link Text')} name="help_menu_link_text">
                                                                <Input name={'help_menu_link_text'}/>
                                                            </FormItem>
                                                            <FormItem label={t('help-menu-link-url','Help Menu Link URL')} name="help_menu_link_url">
                                                                <Input name={'help_menu_link_url'}/>
                                                            </FormItem>
                                                        </VerticalSpace>
                                                    )}  

                                                    <Divider size={'small'}/>

                                                    <Row>
                                                        <Col md={12} sm={24}>
                                                            <label>{t('header-background-color','Header Background Color')}:</label>
                                                            <BrandingColorPicker field={'header_background_color'}/>
                                                        </Col>

                                                        <Col md={12} sm={24}>
                                                            <label>{t('header-text-color','Header Text Color')}:</label>
                                                            <BrandingColorPicker field={'header_text_color'}/>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={12} sm={24}>
                                                            <label>{t('header-highlight-color','Header Highlight Color')}:</label>
                                                            <BrandingColorPicker field={'header_highlight_color'}/>
                                                        </Col>
                                                    </Row>

                                                    {(initialValues.header_background_color !== values.header_background_color ||
                                                        initialValues.header_text_color !== values.header_text_color ||
                                                        initialValues.header_highlight_color !== values.header_highlight_color) && (
                                                            <Button danger ghost block onClick={resetColors} icon={<CloseCircleFilled/>}>{t('button-reset-colors','Reset Colors')}</Button>
                                                    )}
                                                </VerticalSpace>
                                            </Collapse.Panel>

                                            <Collapse.Panel key={'uploads'} header={<>{t('upload-access','Upload Access')} <HelpPopover code={'site-settings-upload-access'}/></>}>
                                                <FormItem
                                                    label={t('allow-lightbox-uploads','Allow Lightbox Uploads')}
                                                    name="lightbox_upload_role_level"
                                                    extra={t('allow-lightbox-uploads-note','Choose the minimum member role that can enable uploads to Lightboxes.')}
                                                >
                                                    <Select name={'lightbox_upload_role_level'}>
                                                        <Select.Option value={'global_content'}>{t('content-manager','Content Manager')}</Select.Option>
                                                        <Select.Option value={'global_library'}>{t('library-manager','Library Manager')}</Select.Option>
                                                        <Select.Option value={'global_tagger'}>{t('taggers','Taggers')}</Select.Option>
                                                        <Select.Option value={'general'}>{t('general-members','General Members')}</Select.Option>
                                                    </Select>
                                                </FormItem>
                                            </Collapse.Panel>

                                            <Collapse.Panel key={'sharing'} header={<>{t('sharing-access','Sharing')} <HelpPopover code={'site-settings-sharing-access'}/></>}>
                                                <FormItem
                                                    label={t('allow-share-downloads','Allow Enabling Share Downloads')}
                                                    name="share_download_role_level"
                                                    extra={t('share-download-role-level-note','Sharing to Choose the minimum member role that can create a share with downloadable files.')}
                                                >
                                                    <Select name={'share_download_role_level'}>
                                                        <Select.Option value={'global_content'}>{t('content-manager','Content Manager')}</Select.Option>
                                                        <Select.Option value={'global_library'}>{t('library-manager','Library Manager')}</Select.Option>
                                                        <Select.Option value={'global_tagger'}>{t('taggers','Taggers')}</Select.Option>
                                                        <Select.Option value={'general'}>{t('general-members','General Members')}</Select.Option>
                                                    </Select>
                                                </FormItem>
                                            </Collapse.Panel>

                                            <Collapse.Panel key={'settings'} header={<>{t('default-view-settings','Default View Settings')} <HelpPopover code={'site-settings-default-view'}/></>}>
                                                <strong>{t('default-landing-page','Default Landing Page')}:</strong>
                                                <br/>
                                                <Select name={'default_landing_page'} style={{width:'100%'}}>
                                                    <Select.Option value={'explore'}>{t('view-all','View All')}</Select.Option>
                                                    <Select.Option value={'homepage'}>{t('homepage','Homepage')}</Select.Option>
                                                </Select>

                                                <Divider style={{margin: '1em 0'}}/>

                                                <VerticalSpace size={2}>
                                                    <Checkbox name={'view_settings.showHeadline'}>{t('show-headline', 'Show Headline')}</Checkbox>
                                                    <Checkbox name={'view_settings.showRating'}>{t('show-rating', 'Show Rating')}</Checkbox>
                                                    <Checkbox name={'view_settings.showFilenames'}>{t('show-filenames', 'Show Filenames')}</Checkbox>
                                                    <Checkbox name={'view_settings.showIcons'}>{t('show-icons', 'Show Grid Icons')}</Checkbox>
                                                    <Checkbox name={'view_settings.autoPlay'}>{t('auto-play-videos', 'Auto-Play Videos')}</Checkbox>
                                                </VerticalSpace>

                                                <Divider style={{margin: '1em 0'}}/>
                                                <strong>{t('default-color-label','Default Color Label')}:</strong>
                                                <ColorLabelSelect
                                                    hideKey
                                                    onSelect={val => setFieldValue('view_settings.colorLabel', val)}
                                                />

                                                <Divider style={{margin: '1em 0'}}/>
                                                <strong>{t('grid-size','Grid Size')}:</strong>
                                                <br/>
                                                <Radio.Group size={'small'} name={'view_settings.gridSize'} value={values.view_settings?.gridSize}>
                                                    <Radio.Button value={'xs'}>
                                                        {t('xs','XS')}
                                                    </Radio.Button>
                                                    <Radio.Button value={'s'}>
                                                        {t('s','S')}
                                                    </Radio.Button>
                                                    <Radio.Button value={'m'}>
                                                        {t('m','M')}
                                                    </Radio.Button>
                                                    <Radio.Button value={'l'}>
                                                        {t('l','L')}
                                                    </Radio.Button>
                                                    <Radio.Button value={'xl'}>
                                                        {t('xL','XL')}
                                                    </Radio.Button>
                                                </Radio.Group>

                                                <br/>
                                                <br/>
                                                <strong>{t('default-asset-details-view-layout','Default Asset Details View Layout')}:</strong>
                                                <br/>

                                                <Select name={'default_asset_modal_layout'} style={{width:'100%'}}>
                                                    <Select.Option value={'vertical'}>{t('vertical','Vertical')}</Select.Option>
                                                    <Select.Option value={'horizontal'}>{t('horizontal','Horizontal')}</Select.Option>
                                                </Select>

                                                <Divider style={{margin:'1em 0'}}/>

                                                <div style={{display:'flex'}}>
                                                    <div style={{flex:'none'}}>
                                                        <strong>{t('grid-background-color','Grid Background Color')}:</strong>
                                                        <TwitterPicker
                                                            color={values.view_settings?.gridColor}
                                                            onChangeComplete={color => setFieldValue('view_settings.gridColor', color.hex)}
                                                            colors={['#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF']}
                                                            width={150}
                                                            triangle={'hide'}
                                                        />
                                                    </div>

                                                    <div style={{flex: 'auto', backgroundColor: values.view_settings?.gridColor, marginLeft:'2em', border:'1px solid #eee'}}/>
                                                </div>

                                                <Divider style={{margin:'1em 0'}}/>

                                                <strong>{t('default-asset-sort-order','Default Asset Sort Order')}:</strong>
                                                <br/>
                                                <Radio.Group name={'default_sort_order'} className={'vertical-checkbox-group'}>
                                                    <Radio value={'created_at-desc'}>
                                                        {t('newest-upload-first','Uploaded: Newest First')}
                                                    </Radio>
                                                    <Radio value={'created_at-asc'}>
                                                        {t('oldest-upload-first','Uploaded: Oldest First')}
                                                    </Radio>
                                                    <Radio value={'updated_at-desc'}>
                                                        {t('recent-edits-first','Recent Edits First')}
                                                    </Radio>
                                                    <Radio value={'updated_at-asc'}>
                                                        {t('oldest-edits-first','Oldest Edits First')}
                                                    </Radio>
                                                    <Radio value={'captured_at-desc'}>
                                                        {t('newest-created-first','Created: Newest First')}
                                                    </Radio>
                                                    <Radio value={'captured_at-asc'}>
                                                        {t('oldest-created-first','Created: Oldest First')}
                                                    </Radio>
                                                    <Radio value={'filename-asc'}>
                                                        {t('filename-a-z','Filename A-Z')}
                                                    </Radio>
                                                    <Radio value={'filename-desc'}>
                                                        {t('filename-z-a','Filename Z-A')}
                                                    </Radio>
                                                    <Radio value={'file_size-desc'}>
                                                        {t('largest-size-first','File Size: Largest First')}
                                                    </Radio>
                                                    <Radio value={'file_size-asc'}>
                                                        {t('smallest-size-first','File Size: Smallest First')}
                                                    </Radio>
                                                    <Radio value={'rating-desc'}>
                                                        {t('highest-rating-first','Rating: Highest First')}
                                                    </Radio>
                                                    <Radio value={'rating-asc'}>
                                                        {t('lowest-rating-first','Rating: Lowest First')}
                                                    </Radio>
                                                </Radio.Group>
                                            </Collapse.Panel>

                                            {currentOrg?.enable_ai && (
                                                <Collapse.Panel key={'ai'} header={<>{t('ai-settings','AI Settings')} <HelpPopover code={'site-settings-ai'}/></>}>
                                                    <VerticalSpace>
                                                        <Checkbox name='enable_transcription'>{t('enable-transcription','Enable Video/Audio transcriptions?')} <HelpPopover code={'enable_transcription'}/></Checkbox>
                                                        <Checkbox name='enable_rekognition'>{t('enable-auto-tagging','Enable Auto-Tagging?')} <HelpPopover code={'enable_rekognition'}/></Checkbox>
                                                        {values.enable_rekognition && (
                                                            <>
                                                                <Checkbox name='enable_nsfw_detection'>{t('enable-nsfw-detection','Enable NSFW Detection?')} <HelpPopover code={'enable_nsfw_detection'}/></Checkbox>
                                                                <Checkbox name='enable_general_member_auto_tags'>{t('show-general-members-auto-tags','Show General Members Auto-Tags?')} <HelpPopover code={'enable_general_member_auto_tags'}/></Checkbox>
                                                                <Checkbox name='omit_auto_tag_results'>{t('omit-auto-tag-results','Omit Auto-Tags from Search Results?')} <HelpPopover code={'omit_auto_tag_results'}/></Checkbox>
                                                                {currentOrg?.enable_face_tagging && (
                                                                    <>
                                                                        <br/>
                                                                        <Checkbox name='enable_face_tagging'>{t('enable-face-tagging','Enable Face Tagging?')} <HelpPopover code={'enable_face_tagging'}/></Checkbox>
                                                                        {/* <Checkbox name='enable_face_training_mode'>{t('enable-face-training','Enable Face Training Mode?')} <HelpPopover code={'enable_face_training_mode'}/></Checkbox> */}
                                                                    </>
                                                                )}
                                                                <br/>
                                                                <FormItem label={<>{t('auto-tag-confidence-threshold','Auto-Tag Confidence Threshold')} <HelpPopover code={'auto_tag_confidence_threshold'}/></>} name='auto_tag_confidence_threshold'>
                                                                    <Input type={'number'} name={'auto_tag_confidence_threshold'} min={0} max={100}/>
                                                                </FormItem>
                                                            </>
                                                        )}

                                                        <Divider>{t('ai-generated-alternative-text', 'AI Alternative Text Generation')}</Divider>
                                                        <Radio.Group name={'alt_text_generation_mode'}>
                                                            <VerticalSpace>
                                                                <Radio value={'off'}>{t('disabled','Disabled').toProperCase()}</Radio>
                                                                <Radio value={'on'}>{t('enabled-on-demand','Enabled (On Demand)')} <HelpPopover code={'alt_text_generation_on_demand'}/></Radio>
                                                                <Radio value={'auto'}>{t('run-auto','Run Automatically')} <HelpPopover code={'alt_text_generation_auto'}/></Radio>
                                                            </VerticalSpace>
                                                        </Radio.Group>

                                                        <FormItem style={{marginTop:'1em'}} label={<>{t('alt-text-generation-prompt','Alt-Text Generation Prompt')} <HelpPopover code={'alt_text_generation_prompt'}/></>} name='alt_text_generation_prompt'>
                                                            <Input.TextArea rows={2} name={'alt_text_generation_prompt'} placeholder={Config.defaultAltTextGenerationPrompt}/>
                                                        </FormItem>
                                                    </VerticalSpace>
                                                </Collapse.Panel>
                                            )}

                                            <Collapse.Panel key={'collections'} header={<>{t('collection-settings','Collection Settings')} <HelpPopover code={'site-settings-collections'}/></>}>
                                                <VerticalSpace>
                                                    <Checkbox name='toc_default_enabled'>{t('enable-toc-default','Enable Table of Contents by default?')} <HelpPopover code={'default_enable_toc'}/></Checkbox>
                                                    <Checkbox name='toc_default_enable_poster_image'>{t('enable-toc-default-poster-image','Enable Poster Image by default?')} <HelpPopover code={'default_enable_toc_poster_image'}/></Checkbox>
                                                </VerticalSpace>
                                            </Collapse.Panel>

                                            <Collapse.Panel key={'meta'} header={<>{t('metadata-settings','Metadata Settings')} <HelpPopover code={'site-settings-metadata'}/></>}>

                                                <VerticalSpace>
                                                    <Checkbox name='enable_product_info'>{t('enable-product-info-meta','Enable Product Info Meta?')} <HelpPopover code={'enable_product_info'}/></Checkbox>
                                                    <Checkbox name='enable_artwork_or_object'>{t('enable-artwork-or-object-meta','Enable Artwork or Object Meta?')} <HelpPopover code={'enable_artwork_or_object'}/></Checkbox>
                                                    <Checkbox name='enable_truncated_date_writing'>{t('enable-truncated-date-writing','Enable Truncated Date Writing to Meta?')} <HelpPopover code={'enable_truncated_date_writing'}/></Checkbox>
                                                    <Checkbox name='overwrite_exif_date'>{t('over-write-exif-date','Enable overwriting EXIF date with IPTC date?')} <HelpPopover code={'enable_overwrite_exif_date'}/></Checkbox>
                                                </VerticalSpace>

                                            </Collapse.Panel>

                                            <Collapse.Panel key={'quick-info'} header={<><span>{t('quick-info-settings','Quick Info Settings')}</span> <HelpPopover code={'site-settings-quick-info'}/></>}>
                                                <QuickInfoSettings options={attrs?.quick_info_options}/>
                                            </Collapse.Panel>

                                            <Collapse.Panel key={'sidebar'} header={<><span>{t('sidebar-settings','Sidebar Settings')}</span> <HelpPopover code={'site-settings-sidebar'}/></>}>
                                                <SidebarSettings/>
                                            </Collapse.Panel>

                                            <Collapse.Panel key={'filterGroups'} header={<><span>{t('filter-groups','Filter Groups')}</span> <HelpPopover code={'site-settings-filter-groups'}/></>}>
                                                <FilterGroups/>
                                            </Collapse.Panel>

                                            <Collapse.Panel key={'cropPresets'} header={<>{t('crop-download-presets','Crop / Download Presets')} <HelpPopover code={'site-settings-crop-presets'}/></>}>
                                                <CropPresets/>
                                            </Collapse.Panel>

                                            <Collapse.Panel key={'downloadSettings'} header={<>{t('download-settings','Download Settings')} <HelpPopover code={'site-settings-download-settings'}/></>}>
                                                <FormItem label={t('filename-format','Filename Format')} name="download_filename_format">
                                                    <Select name={'download_filename_format'} style={{width:'100%'}} defaultValue={null}>
                                                        <Select.Option value={null}>{t('current-filename','Current Filename')}</Select.Option>
                                                        <Select.Option value={'guid'}>GUID</Select.Option>
                                                        <Select.Option value={'short_guid'}>{t('short-guid','Short GUID (8 character)')}</Select.Option>
                                                    </Select>
                                                </FormItem>

                                                <Checkbox name='enable_download_statement'>{t('enable-download-statement','Enable Download Statement?')} <HelpPopover code={'enable_download_statement'}/></Checkbox>
                                                {values.enable_download_statement && (
                                                    <FormItem name='download_statement' style={{marginTop: '.5em'}}>
                                                        <Input.TextArea rows={2} name='download_statement'/>
                                                    </FormItem>
                                                )}

                                                <FormItem label={t('medium-download-size-px','Medium Download Size (px)')} name="medium_download_size" style={{marginTop: '.5em'}}>
                                                    <Input type={'number'} name="medium_download_size"/>
                                                </FormItem>
                                            </Collapse.Panel>

                                            {currentOrg?.federation && (
                                                <Collapse.Panel id={'federation-sharing-panel'} key={'federationSharing'} header={<>{t('enterprise-sharing','Enterprise Sharing')} <HelpPopover code={'site-settings-enterprise-sharing'}/></>}>
                                                    <PermissionForm name={t('all-enterprise-accounts', 'All Enterprise Accounts')} fieldName={'federation_sharing_permissions_attributes'}/>
                                                </Collapse.Panel>
                                            )}

                                            <Collapse.Panel
                                                key={'rights-packages'}
                                                header={<>{t('rights-packages','Rights Packages')} <HelpPopover code={'site-settings-rights-packages'}/></>}
                                                id={'rights-packages-panel'}
                                            >
                                                <FormItem
                                                    name='rights_package_expiration_notify_days'
                                                    label={<>{t('rights-package-expiration-notify-days','Expiration Notification Days')} <HelpPopover code={'rights_package_expiration_notify_days'}/></>}
                                                >
                                                    <Input type='number' name='rights_package_expiration_notify_days'/>
                                                </FormItem>
                                            </Collapse.Panel>

                                            <Collapse.Panel
                                                key={'access-requests'}
                                                header={
                                                    <>
                                                        {t('asset-access-requests','Asset Access Requests')}
                                                        <HelpPopover code={'site-settings-access-requests'}/>

                                                        <EnabledDisabled enabled={values.enable_access_requests} onOff/>
                                                    </>
                                                }
                                                id={'access-requests-panel'}
                                            >

                                                <Switch name='enable_access_requests' checkedChildren={t('enabled','Enabled')} unCheckedChildren={t('disabled','Disabled')}/>

                                                <FormItem name={'access_request_notification_emails'} label={t("access-request-notify-emails","Notify Emails when Access Request Submitted")} style={{margin:'1em 0'}}>
                                                    <FieldArray
                                                        name={'access_request_notification_emails'}
                                                        render={() => (
                                                            <Select name={'access_request_notification_emails'} mode='tags' placeholder={t('placeholder-enter-emails','Enter Emails')}>
                                                                {values.access_request_notification_emails?.map((email) => (
                                                                    <Select.Option value={email} key={email}>{email}</Select.Option>
                                                                ))}
                                                            </Select>
                                                        )}/>
                                                </FormItem>

                                                <FormItem name={'company_name'} label={t("company-name","Company Name")} style={{margin:'1em 0'}}>
                                                    <Input type='text' name="company_name" placeholder={t("placeholder-access-request-company-name","e.g. Parent Company, Inc.")}/>
                                                </FormItem>

                                                <FormItem name={'contact_email'} label={t("contact-email-address","Contact Email Address")} style={{margin:'1em 0'}}>
                                                    <Input type='email' name="contact_email" placeholder={t("placeholder-access-request-contact-email","e.g. access.requests@your-org.com")}/>
                                                </FormItem>

                                                <FormItem name={'contact_address'} label={t("contact-physical-address","Contact Physical Address")} style={{margin:'1em 0'}}>
                                                    <Input name="contact_address" placeholder={t("placeholder-contact-physical-address","e.g. 1234 Company Lane, Los Angeles, CA 90291")}/>
                                                </FormItem>

                                                <FormItem name={'contact_phone'} label={t("contact-phone","Contact Phone")} style={{margin:'1em 0'}}>
                                                    <Input name="contact_phone" placeholder={t("placeholder-contact-phone","e.g. 555-555-5555")}/>
                                                </FormItem>

                                                <FormItem label={<>{t('terms','Terms')} <HelpPopover code={'access-request-terms'}/></>} name='access_request_terms' style={{marginTop: '.5em'}}>
                                                    <Input.TextArea rows={2} name='access_request_terms'/>
                                                    <em><a href={'https://commonmark.org/help/'} target={'_blank'}>Markdown</a> {t('syntax-supported','syntax supported')}.</em>
                                                </FormItem>
                                            </Collapse.Panel>

                                            <Collapse.Panel key={'customizations'} header={<>{t('public-site-customizations','Public Site Customizations')} <HelpPopover code={'site-settings-public-settings'}/></>}>
                                                <Collapse>
                                                    <Collapse.Panel header={<>{t('front-page','Front Page')} <HelpPopover
                                                        code={'site-settings-front-page'}/></>} key={'front'}>
                                                        <FormItem label={t('headline','Headline')} name="public_headline">
                                                            <Input name="public_headline" placeholder={t("placeholder-headline","Headline...")}/>
                                                        </FormItem>

                                                        <FormItem label={t('copy-block','Copy Block')} name='public_text' style={{marginTop: '.5em'}}>
                                                            <Input.TextArea rows={2} name='public_text'/>
                                                            <em><a href={'https://commonmark.org/help/'} target={'_blank'}>Markdown</a> {t('syntax-supported','syntax supported')}.</em>
                                                        </FormItem>

                                                        <FormItem label={t('banner-assets','Banner Assets')} name={'public_banner_asset_guids'}>
                                                            <FieldArray
                                                                name='public_banner_asset_guids'
                                                                render={arrayHelpers => (
                                                                    <div>
                                                                        {values.public_banner_asset_guids && values.public_banner_asset_guids.length > 0 ? (
                                                                            values.public_banner_asset_guids.map((guid, index) => (
                                                                                <div key={index} style={{margin: '.5em 0'}}>
                                                                                    <Space direction={'horizontal'}
                                                                                           style={{width: '100%'}}>
                                                                                        {values.public_banner_asset_guids[index] &&
                                                                                        <AssetMention
                                                                                            guid={values.public_banner_asset_guids[index]}/>}

                                                                                        <Input
                                                                                            name={`public_banner_asset_guids.${index}`}
                                                                                            placeholder="GUID..."
                                                                                            autoComplete={'off'}
                                                                                        />

                                                                                        <Button
                                                                                            onClick={() => arrayHelpers.remove(index)}
                                                                                            icon={<MinusOutlined/>}
                                                                                        />
                                                                                        <Button
                                                                                            onClick={() => arrayHelpers.insert(index, '')}
                                                                                            icon={<PlusOutlined/>}
                                                                                        />
                                                                                    </Space>
                                                                                </div>
                                                                            ))
                                                                        ) : (
                                                                            <Button
                                                                                onClick={() => arrayHelpers.push('')}
                                                                                icon={<PlusOutlined/>}
                                                                            >
                                                                                {t('button-add-asset','Add Asset')}
                                                                            </Button>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            />
                                                        </FormItem>
                                                    </Collapse.Panel>

                                                    <Collapse.Panel header={<>{t('login-page','Login Page')} <HelpPopover
                                                        code={'site-settings-login-page'}/></>} key={'login'}>
                                                        <FormItem label={t('login-background-asset','Login Background Asset')}
                                                                  name="login_background_asset_guid">
                                                            <Space style={{width: '100%'}} direction={'horizontal'}>
                                                                {values.login_background_asset_guid &&
                                                                <AssetMention guid={values.login_background_asset_guid}
                                                                              style={{marginBottom: '.5em'}}/>}
                                                                <Input name="login_background_asset_guid" placeholder="GUID..."
                                                                       autoComplete={'off'}/>
                                                            </Space>
                                                        </FormItem>
                                                    </Collapse.Panel>
                                                </Collapse>
                                            </Collapse.Panel>
                                        </Collapse>

                                        <Collapse>
                                            <Collapse.Panel key={'auto_approve'} header={<>{t('membership-settings','Membership Settings')} <HelpPopover code={'site-settings-membership-auto-approval'}/></>}>
                                                <FormItem name={'enable_membership_requests'}>
                                                    <Checkbox name='enable_membership_requests'> {t('allow-membership-requests','Allow Membership Requests?')} <HelpPopover code={'allow_membership_requests'}/></Checkbox>
                                                </FormItem>
                                                <Divider/>
                                                <FormItem name={'auto_approve_enabled'}>
                                                    <Checkbox name='auto_approve_enabled'> {t('enable-membership-auto-approval-by-email-domain','Enable Membership Auto Approval by Email Domain?')} <HelpPopover code={'enable_auto_approve'}/></Checkbox>
                                                </FormItem>
                                                {values.auto_approve_enabled && (
                                                    <>
                                                        <FormItem label={t('email-domain','Email Domain')} name="auto_approve_domain" showValidateSuccess>
                                                            <Input name="auto_approve_domain" placeholder={t("placeholder-auto-approve-email-domain","e.g. your-company.com")} addonBefore={'@'}/>
                                                        </FormItem>

                                                        <FormItem label={t('welcome-message','Welcome Message')} name='auto_approve_welcome_message'>
                                                            <Input.TextArea rows={2} name='auto_approve_welcome_message'/>
                                                        </FormItem>

                                                        <FormItem label={t('destination-collection','Destination Collection')} name={'auto_approve_collection_id'} extra={t('destination-collection-note','(Optional) User will be taken here after sign-up.')}>
                                                            <AssetGroupChooser type={'collections'} fieldName={'auto_approve_collection_id'} organizerSelection/>
                                                        </FormItem>
                                                    </>
                                                )}
                                            </Collapse.Panel>

                                            <Collapse.Panel key={'sessions'} header={<>{t('session-settings','Session Settings')} <HelpPopover code={'site-settings-sessions'}/></>}>
                                                <FormItem
                                                    name='inactivity_timeout_minutes'
                                                    label={<>{t('inactivity-timeout-minutes','Inactivity Timeout (Minutes)')} <HelpPopover code={'inactivity_timeout_minutes'}/></>}
                                                >
                                                    <Input type='number' name='inactivity_timeout_minutes'/>
                                                </FormItem>
                                            </Collapse.Panel>

                                            <Collapse.Panel key={'sso'} header={<>{t('single-sign-on','Single Sign On')} <HelpPopover code={'site-settings-sso'}/> <EnabledDisabled enabled={values.enable_sso} onOff/></>}>
                                                {currentOrg?.plan_has_sso ? (
                                                    <FormItem name={'enable_sso'}>
                                                        <Checkbox name='enable_sso'>{t('enable-sso','Enable SSO?')} <HelpPopover code={'enable_sso'}/></Checkbox>
                                                    </FormItem>
                                                ) : (
                                                    <em>{t('sso-is-available-for','SSO is available for')} <OrgNavLink to={'/manage/billing'}>{t('workgroup-and-enterprise-plan-levels','Workgroup and Enterprise plan levels')}</OrgNavLink>.</em>
                                                )}

                                                {values.enable_sso && (
                                                    <>
                                                        <FormItem name={'hide_password_login'}>
                                                            <Checkbox name={'hide_password_login'}>{t('hide-password-login','Hide Password Login?')} <HelpPopover code={'hide-password-login'}/></Checkbox>
                                                        </FormItem>

                                                        <FormItem name={'sso_type'} label={t('sso-type','SSO Type')}>
                                                            <Select name={'sso_type'} required>
                                                                {Object.keys(SSOTypes).map((type, i) => (
                                                                    <Select.Option key={i} value={type}>
                                                                        {SSOTypes[type]}
                                                                    </Select.Option>
                                                                ))}
                                                            </Select>
                                                        </FormItem>
                                                    </>
                                                )}

                                                {values.enable_sso && values.sso_type === 'cas' && (
                                                    <>
                                                        <Alert
                                                            style={{marginBottom: '1em'}}
                                                            message={
                                                                <>
                                                                    {t('sign-in-redirect-uri','Sign-in redirect URI')}:
                                                                    <br/>
                                                                    <Typography.Text code copyable>{`https://${location.host}/auth/cas/callback`}</Typography.Text>
                                                                    <br/>
                                                                    <br/>
                                                                    {t('direct-sso-url','Direct SSO URL')}:
                                                                    <br/>
                                                                    <Typography.Text code copyable>{`https://${location.host}/auth/cas?organization_id=${currentOrg.id}`}</Typography.Text>
                                                                </>
                                                            }
                                                        />

                                                        <FormItem label={t('cas-server-url','CAS Server URL')} name="cas_server_url">
                                                            <Input name="cas_server_url" placeholder={t("cas-server-url-placeholder","e.g. https://your-org.com/cas")}/>
                                                        </FormItem>

                                                        <FormItem label={t('cas-login-url','CAS Login URL')} name="cas_login_url">
                                                            <Input name="cas_login_url" placeholder={t("cas-login-url-placeholder","e.g. https://your-org.com/cas/login")}/>
                                                        </FormItem>
                                                    </>
                                                )}

                                                {values.enable_sso && values.sso_type === 'openid_connect' && (
                                                    <>
                                                        <Alert
                                                            style={{marginBottom: '1em'}}
                                                            message={
                                                                <>
                                                                    {t('sign-in-redirect-uri','Sign-in redirect URI')}:
                                                                    <br/>
                                                                    <Typography.Text code
                                                                                     copyable>{`https://${location.host}/auth/openid_connect/callback`}</Typography.Text>
                                                                    <br/>
                                                                    <br/>
                                                                    {t('direct-sso-url','Direct SSO URL')}:
                                                                    <br/>
                                                                    <Typography.Text code copyable>{`https://${location.host}/auth/openid_connect?organization_id=${currentOrg.id}`}</Typography.Text>
                                                                </>
                                                            }
                                                        />

                                                        <FormItem label={t('openid-client-id','OpenID Client ID')} name="openid_client_id">
                                                            <Input name="openid_client_id" placeholder={t("placeholder-openid-client-id","e.g. asdf1234zxcv")}/>
                                                        </FormItem>

                                                        <FormItem label={t('openid-client-secret','OpenID Client Secret')} name="openid_client_secret">
                                                            <Input.Password name="openid_client_secret"
                                                                            placeholder={t("placeholder-opendi-client-secret","e.g. asdf1234zxcv")}
                                                                            autoComplete='new-password'/>
                                                        </FormItem>

                                                        <FormItem label={t('openid-host','OpenID Host')} name="openid_host">
                                                            <Input name="openid_host" placeholder={t("placeholder-openid-host","e.g. your-company.okta.com")}/>
                                                        </FormItem>
                                                    </>
                                                )}

                                                {values.enable_sso && values.sso_type === 'saml' && (
                                                    <>
                                                        <FormItem name={'encrypt_saml'}>
                                                            <Checkbox name={'encrypt_saml'}>{t('encrypt-saml','Encrypt SAML assertions?')}</Checkbox>
                                                        </FormItem>

                                                        <FormItem name={'saml_email_attribute_name'} label={t('saml-email-attribute-name', 'SAML Email Attribute Name')}>
                                                            <Input name="saml_email_attribute_name" placeholder={t("placeholder-saml_email_attribute_name","e.g. email or mail")}/>
                                                        </FormItem>

                                                        <Alert
                                                            style={{marginBottom: '1em'}}
                                                            message={
                                                                <>
                                                                    {t('single-sign-on-url','Single Sign On URL')}:
                                                                    <br/>
                                                                    <Typography.Text code copyable>{getSamlUrl('callback')}</Typography.Text>

                                                                    <br/>
                                                                    <br/>
                                                                    {t('audience-uri','Audience URI (SP Entity ID)')}:
                                                                    <br/>
                                                                    <Typography.Text code copyable>{getSamlUrl('metadata')}</Typography.Text>

                                                                    <br/>
                                                                    <br/>
                                                                    {t('name-id-format','Name ID Format')}:
                                                                    <Typography.Text code>EmailAddress</Typography.Text>

                                                                    <br/>
                                                                    <br/>
                                                                    {t('attribute-statements','Attribute Statements (optional)')}:
                                                                    <br/>
                                                                    <Descriptions bordered size={'small'} column={1} style={{width:'100%', marginBottom:'.5em'}}>
                                                                        <Descriptions.Item label={'first_name'}>
                                                                            <Typography.Text code>user.firstName</Typography.Text>
                                                                        </Descriptions.Item>
                                                                        <Descriptions.Item label={'last_name'}>
                                                                            <Typography.Text code>user.lastName</Typography.Text>
                                                                        </Descriptions.Item>
                                                                        <Descriptions.Item label={'groups'}>
                                                                            <Typography.Text code>user.groups</Typography.Text>
                                                                        </Descriptions.Item>
                                                                    </Descriptions>
                                                                    <br/>
                                                                    <br/>
                                                                    {t('direct-sso-url','Direct SSO URL')}:
                                                                    <br/>
                                                                    <Typography.Text code copyable>{getSamlUrl()}</Typography.Text>
                                                                </>
                                                            }
                                                        />

                                                        <Flex align={'center'} gap={4}>
                                                            <FormItem label={t('identity-provider-metadata-url','Identity Provider Metadata URL')} name="saml_idp_url" style={{flex: 1}}>
                                                                <Input name="saml_idp_url" placeholder="e.g. https://dev-745.okta.com/app/exk21/sso/saml/metadata"/>
                                                            </FormItem>

                                                            <Button
                                                                disabled={!values.saml_idp_url}
                                                                loading={verifyingSaml}
                                                                onClick={verifySaml}
                                                                icon={<CheckOutlined/>}
                                                                style={{flex: 0, marginTop: 5}}
                                                            >
                                                                {t('button-verify','Verify')}
                                                            </Button>
                                                        </Flex>
                                                    </>
                                                )}

                                                <FormItem label={<>{t('sso-groups-field','SSO Groups Field')} <HelpPopover code={'sso_groups_field'}/></>} name="sso_groups_field" style={{marginTop:'1em'}}>
                                                    <Input name="sso_groups_field" placeholder={'groups'}/>
                                                </FormItem>
                                            </Collapse.Panel>
                                        </Collapse>

                                        <FormItem name="submit">
                                            {isSubmitting ? (
                                                <Button block type="primary" icon={<PoweroffOutlined/>}
                                                        loading>{t('saving','Saving')}...</Button>
                                            ) : (
                                                <SubmitButton block>{t('button-save','Save')}</SubmitButton>
                                            )}
                                        </FormItem>
                                    </Space>
                                </Form>
                            );
                        }}
                    </Formik>
                )}
            </Skeleton>
        </Card>)
    );
}
import { arrayMoveImmutable } from 'array-move';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import setTitle from "../helpers/setTitle";
import {getLocale} from "../widgets/DateRangePicker";
import {useTranslation} from "react-i18next";
import FilterGroups from "~/components/manage/FilterGroups";
import TextArea from "antd/lib/input/TextArea";
import {useFilterTitles} from "@/components/explore/ExploreFilters";
import {ColorLabelSelect} from "@/components/explore/ViewMenu";
import PermissionForm from "./PermissionForm";

const CropPresets = ()=> {
    const {t} = useTranslation();
    const org = useCurrentOrg()
    const [loading, setLoading] = useState(true);
    const [dataSource, setDataSource] = useState([]);

    const load = ()=>{
        api('/api/crop_presets').then(res => {
            setLoading(false)
            setDataSource(res.data)
        })
    }

    useEffect(()=>{
        org && load()
    }, [org?.id])

    const DragHandle = SortableHandle(() => (
        <MenuOutlined
            style={{
                cursor: 'grab',
                color: '#999',
            }}
        />
    ));

    const onChange = (cp, field) => {
        return  value => {
            const data = {}
            data[field] = value
            api.put(`/api/crop_presets/${cp.id}`, {crop_preset: data}).then(res => {
                message.success(`${field} ${t('updated','updated')}!`)
            })
            dataSource.forEach(dataCp => { if(dataCp.id == cp.id) dataCp[field] = value } )
            setDataSource([...dataSource])
        }
    }

    const columns = [
        {
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle />,
        },
        {
            dataIndex: 'enabled',
            render: (enabled, cp) => {
                const change = value => {
                    dataSource.forEach(dataCp => { if(dataCp.id == cp.id) dataCp.enabled = value } )
                    setDataSource([...dataSource])

                    api.put(`/api/crop_presets/${cp.id}`, {crop_preset: {enabled: value}})
                }

                return <AntSwitch checked={enabled} checkedChildren={t('enabled','Enabled')} unCheckedChildren={t('disabled','Disabled')} onChange={change}/>
            }
        },
        {
            title: t('name','Name'),
            dataIndex: 'name',
            className: 'drag-visible',
            render: (name, cp) => <Typography.Text editable={{onChange: onChange(cp, 'name')}}>{name}</Typography.Text>
        },
        {
            title: t('width','Width'),
            dataIndex: 'width',
            render: (width, cp) => <Typography.Text editable={{onChange: onChange(cp, 'width')}}>{width}</Typography.Text>
        },
        {
            title: t('height','Height'),
            dataIndex: 'height',
            render: (height, cp) => <Typography.Text editable={{onChange: onChange(cp, 'height')}}>{height}</Typography.Text>
        },
        {
            render: cp => {
                const destroy = ()=> {
                    api.delete(`/api/crop_presets/${cp.id}`).then(res => {
                        message.success(t('message-crop-preset-removed','Crop Preset removed'))
                        load()
                    })
                }

                return <Popconfirm title={t('confirm-delete','Delete?')} onConfirm={destroy}><Button ghost danger icon={<DeleteOutlined/>}/></Popconfirm>
            }
        }
    ];

    const SortableItem = SortableElement((props) => <tr {...props} />);
    const SortableBody = SortableContainer((props) => <tbody {...props} />);

    const onSortEnd = ({ oldIndex, newIndex}) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable(dataSource.slice(), oldIndex, newIndex).filter(
                (el) => !!el,
            );
            console.log('Sorted items: ', newData);

            api.put('/api/crop_presets/update_position', {oldIndex, newIndex}).then(res => {
                message.success(t('message-sort-order-updated','Sort order updated!'))
            })

            setDataSource(newData);
        }
    };

    const DraggableContainer = (props) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataSource.findIndex((x) => x.position === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    const [modalVisible, setModalVisible] = useState()
    const autoFocusInput = useRef()
    const clickNew = ()=> {
        setModalVisible(true)
        setTimeout(()=>{
            autoFocusInput.current?.focus()
        }, 100)
    }

    const apiPath = '/api/crop_presets'

    return (<>
        <Formik
            initialValues={{}}
            onSubmit={(values, actions) => {
                api({
                    method: 'post',
                    url: apiPath,
                    data: {crop_preset: values}
                }).then((res)=>{
                    actions.setSubmitting(false)
                    message.success(t(`message-crop-preset-added`,`Crop Preset Added!`))
                    actions.resetForm({});
                    setModalVisible(false)
                    setDataSource([...dataSource, res.data])

                }).catch((error)=>{
                    console.log(error)
                    if(error) {
                        console.error(error.response?.data)
                        message.error(`Error: ${JSON.stringify(error.response?.data)}`)
                        actions.setSubmitting(false)
                    }
                })
            }}
            validationSchema={
                Yup.object({
                    name: Yup.string().required(true),
                    width: Yup.number().required(t('required','Required')),
                    height: Yup.number().required(t('required','Required')),
                })
            }
        >
            {({submitForm, values})=>(
                <Modal
                    open={modalVisible}
                    title={<>New Crop Preset</>}
                    footer={
                        <Space>
                            <Button onClick={submitForm} type={'primary'} icon={<SaveOutlined/>}>{t('button-save','Save')}</Button>
                            <Button onClick={()=> setModalVisible(false)}>{t('button-cancel','Cancel')}</Button>
                        </Space>
                    }
                >
                    <Form layout='vertical'>
                        <FormItem required showValidateSuccess name='name'>
                            <FloatLabel label={t('name','Name')} name={'name'} value={values.name} description={t('placeholder-crop-preset-name','e.g. Banner Image')}>
                                <Input size={'large'} required name='name' ref={autoFocusInput} autoComplete='off'/>
                            </FloatLabel>
                        </FormItem>

                        <FormItem required showValidateSuccess name='width'>
                            <FloatLabel label={t('width','Width')} name={'width'} value={values.width}>
                                <Input type='number' size={'large'} required name='width' autoComplete='off'/>
                            </FloatLabel>
                        </FormItem>

                        <FormItem required showValidateSuccess name='height'>
                            <FloatLabel label={t('height','Height')} name={'height'} value={values.height}>
                                <Input type='number' size={'large'} required name='height' autoComplete='off'/>
                            </FloatLabel>
                        </FormItem>
                    </Form>
                </Modal>
                )}
                </Formik>
        <VerticalSpace>
            <Table
                size={'small'}
                pagination={false}
                dataSource={dataSource}
                columns={columns}
                rowKey="position"
                loading={loading}
                components={{
                    body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRow,
                    },
                }}
            />
            <Button onClick={clickNew} type={'primary'} ghost icon={<PlusOutlined/>}>{t('button-add-new','Add New')}</Button>
        </VerticalSpace>
    </>);
}

const BrandingColorPicker = ({field})=> {
    const currentOrg = useCurrentOrg()
    const {dispatch} = useContext(SessionContext);

    const {values, setFieldValue} = useFormikContext()

    const onChange = color => {
        setFieldValue(field, color.hex)
        currentOrg[field] = color.hex

        dispatch({
            type:'org_updated',
            org: {...currentOrg},
        });
    }

    return (
        <div style={{margin:'1em 0'}}>
            <SketchPicker
                disableAlpha
                color={values[field]}
                onChangeComplete={onChange}
            />
        </div>
    )
}

const SidebarSettings = ()=>{
    const {t} = useTranslation();

    const {values, setFieldValue} = useFormikContext()

    const SortableItem = SortableElement((props) => <div {...props} />);
    const SortableBody = SortableContainer((props) => <div {...props} />);

    const DragHandle = SortableHandle(() => (
        <MenuOutlined
            style={{
                cursor: 'grab',
                color: '#999',
            }}
        />
    ));

    const getTitle = name => {
        switch(name) {
            case 'taxonomies': return 'Tag Trees'
            case 'date_created': return 'Date Created'
            case 'events': return 'Events'
            case 'filters': return 'Filters'
        }
    }

    const onSortEnd = ({ oldIndex, newIndex}) => {
        if (oldIndex !== newIndex) {
            const newItems = arrayMoveImmutable(values.sidebar_settings.slice(), oldIndex, newIndex).filter(el => !!el);

            setFieldValue('sidebar_settings', newItems)
        }
    };

    return (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
        >
            {values.sidebar_settings?.map((item, index) => (
                <SortableItem style={{display:'flex', margin:'.5em 0'}} index={index} key={item.name}>
                    <Space>
                        <DragHandle/>
                        {t(`sidebar-${item.name}`, getTitle(item.name))}
                    </Space>

                    <div style={{marginLeft:'auto'}}>
                        {['admin', 'general', 'public'].map(type => {
                            return (
                                <Checkbox name={`sidebar_settings[${index}].${type}`} key={type}>{t(type, type.toProperCase())}</Checkbox>
                            );
                        })}
                    </div>
                </SortableItem>
            ))}
        </SortableBody>
    )
}

const SortableQuickInfoItem = SortableElement((props) => <QuickInfoItem {...props} />);
const SortableQuickInfoBody = SortableContainer((props) => <div {...props} />);

const QuickInfoItem = ({option, collection, i, isUsed}) => {
    const {t} = useTranslation();

    const DragHandle = SortableHandle(() => (
        <MenuOutlined
            style={{
                cursor: 'grab',
                color: '#999',
            }}
        />
    ));

    const {values, setFieldValue} = useFormikContext()

    const remove = ()=>{
        values.quick_info_settings.splice(i,1)
        setFieldValue('quick_info_settings', values.quick_info_settings)
    }

    return (
        <div style={{display:'flex', margin:'.5em 0'}} id={`quick-info-option-${option.name}`}>
            <Space style={{width:'100%'}}>
                <DragHandle/>
                {option.type === 'CustomMeta' && <span>{t('custom-meta','Custom Meta')}:</span>}
                {option.name}

                <Button icon={<DeleteOutlined/>} onClick={remove} style={{marginLeft:'auto'}} size={'small'}/>
            </Space>
        </div>
    )
}
const QuickInfoSettings = ({options})=>{
    const {t} = useTranslation();

    const {values, setFieldValue} = useFormikContext()

    const unusedFields = Object.keys(options).filter(f => values.quick_info_settings.indexOf(f) === -1);

    const onSortEnd = ({ oldIndex, newIndex, collection }) => {
        console.log('onSortEnd', oldIndex, newIndex, collection)

        const newData = arrayMoveImmutable(values.quick_info_settings, oldIndex, newIndex);

        setFieldValue('quick_info_settings', newData)
    }

    return (
        <>
            <Divider>{t('items','Items')}</Divider>

            <SortableQuickInfoBody
                useDragHandle
                onSortEnd={onSortEnd}
            >
                {!values.quick_info_settings?.length && (
                    <div style={{height:100, width: '100%', border: '2px dashed #ddd', padding: '2em', textAlign: 'center'}}>
                        <em>{t('drag-items-here','Drag Items Here...')}</em>
                    </div>
                )}

                {values.quick_info_settings?.map((f,i) => {
                    return (
                        <SortableQuickInfoItem
                            key={f}
                            option={options[f]}
                            index={i}
                            i={i}
                        />
                    );
                })}
            </SortableQuickInfoBody>

            <Divider>{t('unused-items','Unused Items')}</Divider>

            <VerticalSpace>
                {unusedFields.map(f => {
                    const add = () => {
                        values.quick_info_settings.push(f);

                        setFieldValue('quick_info_settings', _.uniq(values.quick_info_settings))
                    }

                    const option = options[f]

                    return (
                        <Space>
                            <Button onClick={add} size={'small'} icon={<PlusOutlined/>}/>

                            {option.type === 'CustomMeta' && <span>{t('custom-meta','Custom Meta')}:</span>}
                            {option.name}
                        </Space>
                    );
                })}
            </VerticalSpace>
        </>
    )
}