import React, {useContext, useEffect, useState} from "react";

import {Elements, CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import {
    Card,
    Button,
    message,
    Upload,
    Divider,
    Space,
    Tooltip,
    Popconfirm,
    Tabs,
    Badge,
    Row,
    Col,
    Alert,
    Tag, Table, Skeleton, Descriptions, Statistic, Select as AntSelect,
    notification
} from 'antd';

const {TabPane} = Tabs;

import {Formik} from 'formik';
import {Form, FormItem, Input, SubmitButton, Radio, Checkbox, Select, Switch, DatePicker} from "formik-antd";
import filesize from 'filesize'

import api from "../api";
import {
    MinusOutlined,
    DeleteOutlined,
    CreditCardOutlined,
    CheckOutlined,
    ShoppingOutlined,
    EditOutlined,
    CloseCircleOutlined,
    UserSwitchOutlined,
    EyeOutlined,
    DollarOutlined,
    GiftOutlined,
    ClockCircleOutlined,
    LineChartOutlined, ThunderboltOutlined, HddOutlined, MailOutlined, RollbackOutlined
} from "@ant-design/icons";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";

import useCurrentOrg from "../helpers/useCurrentOrg";
import {SessionContext} from "../../contexts/SessionContext";
import OrgNavLink from "../helpers/OrgNavLink";
import TimeAgo from "../helpers/TimeAgo";
import {InvoiceIcon} from "../helpers/icons";
import {AbilityContext} from "../helpers/Can";
import HelpPopover from "../HelpPopover";
import moment from "moment";
import setTitle from "../helpers/setTitle";
import useCurrentUser from "../helpers/useCurrentUser";
import ResetButton from "formik-antd/src/reset-button/index";
import {useTranslation} from "react-i18next";

export default ({afterUpdate}) => {
    const {t} = useTranslation();
    setTitle(t('plan-and-billing','Plan and Billing'))

    const org = useCurrentOrg();
    const {dispatch: sessionDispatch} = useContext(SessionContext);

    const stripePromise = loadStripe(Config.stripePublicKey);

    const [attrs, setAttrs] = useState()
    useEffect(()=>{
        if(org) setAttrs(org)
    }, [org?.id])

    const tabHash = location.hash?.replace('#','') || 'plan'
    const [tab, setTab] = useState(tabHash);

    useEffect(()=>{
        setTab(tabHash)
    },[location.hash])

    const tabClick = key =>{
        setTab(key)
        location.hash = key
    }

    const [canceling, setCanceling] = useState()
    const cancelSubscription = ()=>{
        setCanceling(true)
        api.post(`/api/organizations/${org.id}/cancel_subscription`).then(res => {
            sessionDispatch({type: 'org_updated', org: res.data});
            setAttrs(res.data)
            setCanceling(false)
            message.success(t(`message-subscription-canceled`,`Subscription canceled.`))
        })
    }

    const card = org?.stripe_details?.card

    const statusColors = {
        'active': 'green',
        'past_due': 'red',
        'canceled': 'grey'
    }

    // -----------
    // Super Admin
    // -----------

    const currentUser = useCurrentUser()

    const [togglingInvoicing, setTogglingInvoicing] = useState()

    const toggleInvoicing = ()=>{
        setTogglingInvoicing(true)
        api.post(`/api/organizations/${org.id}/toggle_invoicing`).then(res => {
            setTogglingInvoicing(false)
            sessionDispatch({type: 'org_updated', org: res.data});
            setAttrs(res.data)
            message.success('Invoicing toggled!')
        })
    }

    const [togglingFree, setTogglingFree] = useState()
    const toggleFree = ()=>{
        setTogglingFree(true)
        api.post(`/api/organizations/${org.id}/toggle_free`).then(res => {
            setTogglingFree(false)
            sessionDispatch({type: 'org_updated', org: res.data});
            setAttrs(res.data)
            message.success('Free toggled!')
        })
    }

    const [resettingSubscription, setResettingSubscription] = useState()
    const resetSubscription = ()=>{
        setResettingSubscription(true)
        api.post(`/api/organizations/${org.id}/reset_subscription`).then(res => {
            setResettingSubscription(false)

            if(res.data.error) {
                notification.error({
                    message: t('card-error','Card Error'),
                    description: JSON.stringify(res.data.error)
                })
                return
            }

            sessionDispatch({type: 'org_updated', org: res.data});
            setAttrs(res.data)
            message.success('Subscription Reset!')
        })
    }

    const renderPrice = (code, interval) => {
        if(org?.free) return 'free'

        const sp = StripeProducts[code]
        return sp?.amount && `$${n(((sp.amount / 100.0) * (interval == 'year' && !sp.yearly_only ? YearDiscount : 1)).toFixed(2))}/${interval == 'year' ? 'yr' : 'mo'}`
    }

    const [activating, setActivating] = useState()
    const activate = ()=>{
        setActivating(true)
        api.post(`/api/organizations/${org.id}/activate_subscription`).then(res => {
            setActivating(false)

            if(res.data.error) {
                notification.error({
                    message: t('card-error','Card Error'),
                    description: JSON.stringify(res.data.error)
                })
                return
            }

            sessionDispatch({type: 'org_updated', org: res.data});
            setAttrs(res.data)
            message.success(t(`message-subscription-activated`,`Subscription activated.`))
        })
    }

    const cancelable = ['active', 'past_due','unpaid'].indexOf(org?.stripe_status) != -1

    const coupon = org?.stripe_coupon?.coupon

    const [settingLevel, setSettingLevel] = useState()

    const adminUpdateFreeAccountLevel = stripe_product_code => {
        setSettingLevel(true)
        api.post(`/api/organizations/${org.id}/set_product_code`, {stripe_product_code}).then(res => {
            setSettingLevel(false)
            sessionDispatch({type: 'org_updated', org: res.data});
            setAttrs(res.data)
            message.success(t('message-account-level-updated','Account Level updated!'))
        })
    }

    if(org?.custom_pricing) {
        return (
            <Row>
                <Col xs={24} lg={12}>
                    <Alert
                        type={'info'}
                        showIcon
                        style={{margin:'2em 0'}}
                        message={
                            <>
                                {t('custom-pricing-enabled', 'Custom Pricing Enabled')}
                            </>
                        }
                    />
                </Col>
            </Row>
        )
    }

    return (
        <Tabs
            activeKey={tab}
            onTabClick={tabClick}
        >
            <TabPane tab="Plan" key="plan">
                <Row gutter={16}>
                    <Col lg={12} xs={24}>
                        <Space direction={'vertical'} style={{width:'100%'}}>
                            <Card title={<><ShoppingOutlined /> {t('subscription','Subscription')} <div style={{float:'right'}}><Tag color={statusColors[org?.stripe_status]}>{org?.free ? t('free','Free') : t(org?.stripe_status, org?.stripe_status?.toProperCase()) || t('pending','Pending')}</Tag></div></>}>
                                {(!org?.free || !org?.custom_pricing) && (
                                    <>
                                        {coupon && (
                                            <Alert
                                                type={'success'} showIcon
                                                style={{marginBottom:'1em'}}
                                                // description={'Discount'}
                                                message={
                                                    <>
                                                        {t('active-discount','Active Discount')}: <strong>{coupon.name} - {coupon.percent_off}% {t('off','off')} {coupon.duration}</strong>
                                                    </>
                                                }
                                            />
                                        )}

                                        {(org?.stripe_status == 'unpaid' || org?.stripe_status == 'past_due') && (
                                            <Alert
                                                type={'error'} showIcon
                                                style={{marginBottom:'1em'}}
                                                message={t('subscription-status','Subscription Status')}
                                                description={
                                                    <>
                                                        {t('your-account-is','Your account is {{status}}', {status: org.stripe_status})}. {t('please','Please')} <OrgNavLink to={'/manage/billing#payment-method'}>{t('update-your-payment-method','update your Payment Method')}</OrgNavLink>.
                                                    </>
                                                }
                                            />
                                        )}

                                        {org?.stripe_status == 'trialing' && (
                                            <Alert
                                                type={'info'} showIcon
                                                style={{marginBottom:'1em'}}
                                                message={t('your-account-is-in-trial-mode','Your Account is in Trial Mode')}
                                                description={
                                                    <>
                                                        {card ? (
                                                            <>{t('activate-now-to-get-full-account-features.','Activate now to get full account features and storage.')}</>
                                                        ) : (
                                                            <>{t('your-free-trial-ends','Your free trial ({{size}} GB) ends', {size: TrialGBLimit})} <TimeAgo date={org.trial_ends_at}/>.</>
                                                        )}
                                                    </>
                                                }
                                                action={
                                                    <>
                                                        <Popconfirm onConfirm={activate} title={t('confirm-are-you-sure','Are you sure?')}>
                                                            <Button icon={<CheckOutlined/>} disabled={!card && !org?.invoicing && !org?.free} loading={activating} type={'primary'}>{t('button-activate','Activate')}</Button>
                                                        </Popconfirm>
                                                    </>
                                                }
                                            />
                                        )}

                                        {!card && !org?.invoicing && !org?.free && (
                                            <Alert
                                                type={'warning'} showIcon
                                                style={{marginBottom:'1em'}}
                                                message={
                                                    <>
                                                        {t('please','Please')} <OrgNavLink to={'/manage/billing#payment-method'}>{t('add-payment-method','Add a Payment Method')}</OrgNavLink> {t('to-upgrade','to upgrade')}.
                                                    </>
                                                }
                                            />
                                        )}
                                    </>
                                )}

                                <Formik
                                    initialValues={attrs}
                                    enableReinitialize={true}
                                    onSubmit={(values, actions) => {
                                        const data = _.pick(values, 'stripe_interval','stripe_product_code','storage_tier')
                                        api.post(`/api/organizations/${org.id}/update_plan`, data).then((res)=>{
                                            sessionDispatch({type: 'org_updated', org: res.data});
                                            setAttrs(res.data)

                                            actions.setSubmitting(false)
                                            message.success(t(`plan-updated!`,`Plan Updated!`))

                                            actions.resetForm({});

                                            afterUpdate && afterUpdate()

                                        }).catch((error)=>{
                                            if(error) {
                                                console.error(error.response?.data)
                                                // message.error(`Error: ${JSON.stringify(error.response?.data)}`)
                                                actions.setSubmitting(false)
                                            }
                                        })
                                    }}
                                >
                                    {({setFieldValue, values, submitForm, dirty, isSubmitting}) => (
                                        <Form layout='vertical'>
                                            <Row gutter={16}>
                                                <Col span={24}>
                                                    <Space direction={'vertical'} size={'middle'}>
                                                        <FormItem name={'stripe_interval'} label={t('payment-plan','Payment Plan')}>
                                                            <Radio.Group name={'stripe_interval'} disabled={!card && !org?.invoicing}>
                                                                <Space direction="vertical">
                                                                    <Radio value={'month'}>{t('monthly','Monthly')}</Radio>
                                                                    <Radio value={'year'}>{t('yearly','Yearly')} <Tag color={'blue'}>{Math.ceil((1 - YearDiscount) * 100)}% {t('off','off')}</Tag></Radio>
                                                                </Space>
                                                            </Radio.Group>
                                                        </FormItem>

                                                        <FormItem name={'stripe_product_code'} label={<>{t('account-level','Account Level')} <small style={{marginLeft:'1em'}}><a href={'https://www.mediagraph.io/pricing'} target={'_blank'} style={{textDecoration:'underline'}}>{t('compare','Compare')}</a></small></>}>
                                                            <Radio.Group name={'stripe_product_code'} onChange={()=> setFieldValue('storage_tier', 0) }>
                                                                <Space direction="vertical">
                                                                    {Object.keys(StripeProducts).map(key => {
                                                                        const sp = StripeProducts[key]
                                                                        const current = key === org?.stripe_product_code

                                                                        if(sp.legacy && !current) return <></>;

                                                                        return (
                                                                            <Radio key={key} value={key} disabled={!card && !org?.invoicing && key != 'free'}>
                                                                                {t(sp.name,sp.name)}  <small>{renderPrice(key, values?.stripe_interval)}</small>

                                                                                {current && (
                                                                                    <Tag color={'green'} style={{marginLeft:'1em'}}>{t('current-plan','Current Plan')}</Tag>
                                                                                )}
                                                                            </Radio>
                                                                        );
                                                                    })}
                                                                </Space>
                                                            </Radio.Group>
                                                        </FormItem>

                                                        <FormItem name={'storage_tier'} label={<>{t('included-storage','Included Storage')} <HelpPopover code={'storage_tier'}/></>}>
                                                            <Radio.Group name={'storage_tier'} disabled={!card && !org?.invoicing}>
                                                                <Space direction={'vertical'} style={{width:'100%'}}>
                                                                    {StripeProducts[values?.stripe_product_code]?.prices?.map((price,i) => (
                                                                        price.to != 'inf' &&
                                                                        <Radio
                                                                            value={i}
                                                                            key={i}
                                                                            // disabled={values.stripe_product_code == org?.stripe_product_code && i < org.storage_tier}
                                                                        >
                                                                            {price.to < 1024 ? `${price.to}GB` : `${price.to / 1000}TB`}
                                                                            &nbsp;<small>
                                                                                {price.fee && (
                                                                                    <>
                                                                                        +${n(((price.fee * (values.stripe_interval == 'year' ? YearDiscount : 1))/ 100.0).toFixed(2))}/mo
                                                                                    </>
                                                                                )}
                                                                            </small>

                                                                            {values.stripe_product_code == org?.stripe_product_code && i == org.storage_tier && (
                                                                                <Tag color={'green'} style={{marginLeft:'1em'}}>{t('current-storage-plan','Current Storage Plan')}</Tag>
                                                                            )}
                                                                        </Radio>
                                                                    ))}
                                                                </Space>
                                                            </Radio.Group>
                                                        </FormItem>

                                                        <Popconfirm onConfirm={submitForm} title={t('confirm-update-plan','Update Plan?')}>
                                                            <Button
                                                                disabled={
                                                                    (!org || (!card && !org?.invoicing)) ||
                                                                    (values?.stripe_product_code == org?.stripe_product_code &&
                                                                        values?.stripe_interval == org?.stripe_interval &&
                                                                        values?.storage_tier == org?.storage_tier)
                                                                }
                                                                icon={<CheckOutlined/>}
                                                                type={'primary'}
                                                                loading={isSubmitting}
                                                            >
                                                                {t('button-update-plan','Update Plan')}
                                                            </Button>
                                                        </Popconfirm>
                                                    </Space>
                                                </Col>
                                            </Row>

                                        </Form>
                                    )}
                                </Formik>
                            </Card>
                        </Space>
                    </Col>
                    <Col lg={12} xs={24}>
                        <Space direction={'vertical'} style={{width:'100%'}}>
                            <Card title={<><LineChartOutlined/> {t('current-usage','Current Usage')}</>}>
                                <CurrentUsage/>
                            </Card>

                            {!org?.free && (
                                <Card title={<><EditOutlined/> {t('management','Management')}</>}>
                                    <Popconfirm
                                        title={cancelable && t('confirm-cancel-subscription?','Cancel Subscription?')}
                                        onConfirm={cancelable && cancelSubscription}
                                    >
                                        <Button danger icon={<CloseCircleOutlined/>} loading={canceling} ghost disabled={!cancelable}>{t('button-cancel-subscription','Cancel Subscription')}...</Button>
                                    </Popconfirm>
                                </Card>
                            )}

                            {currentUser?.super_admin && (
                                <Card title={<><ThunderboltOutlined /> Super Admin</>}>
                                    <Space direction={'vertical'}>
                                        <Space>
                                            <Button
                                                icon={<EyeOutlined/>}
                                                href={`https://dashboard.stripe.com/${Config.releaseStage == 'development' ? 'test/' : ''}customers/${org?.stripe_customer_id}`}
                                                target={'_blank'}
                                                type={'text'}
                                            >
                                                View on Stripe
                                            </Button>

                                            <Button
                                                icon={<EditOutlined/>}
                                                href={`https://dashboard.stripe.com/${Config.releaseStage == 'development' ? 'test/' : ''}subscriptions/${org?.stripe_subscription_id}/edit`}
                                                target={'_blatnk'}
                                                type={'text'}
                                            >
                                                Edit on Stripe
                                            </Button>
                                        </Space>

                                        <Space>
                                            <Popconfirm onConfirm={toggleInvoicing} title={'Toggle Invoicing?'}>
                                                <Button icon={<InvoiceIcon/>} loading={togglingInvoicing} type={'primary'} ghost>{org?.invoicing ? 'Disable' : 'Enable'} Invoicing</Button>
                                            </Popconfirm>

                                            <Popconfirm onConfirm={toggleFree} title={'Toggle Free?'}>
                                                <Button icon={<GiftOutlined/>} loading={togglingFree} type={'primary'} ghost>{org?.free ? 'Disable' : 'Enable'} Free</Button>
                                            </Popconfirm>

                                            {(org?.trial_ends_at || org?.stripe_status === 'canceled') && (
                                                <Popconfirm onConfirm={resetSubscription} title={'Reset Subscription?'}>
                                                    <Button icon={<RollbackOutlined />} loading={resettingSubscription} type={'primary'} ghost>Reset Subscription</Button>
                                                </Popconfirm>
                                            )}

                                            {org?.free && (
                                                <AntSelect onChange={adminUpdateFreeAccountLevel} value={org?.stripe_product_code} loading={settingLevel}>
                                                    <AntSelect.Option value={'essential'}>Essential</AntSelect.Option>
                                                    <AntSelect.Option value={'essential_ai'}>Essential AI</AntSelect.Option>
                                                    <AntSelect.Option value={'enterprise2'}>Enterprise</AntSelect.Option>
                                                    <AntSelect.Option value={'agency'}>Agency</AntSelect.Option>
                                                </AntSelect>
                                            )}
                                        </Space>
                                    </Space>
                                </Card>
                            )}
                        </Space>
                    </Col>
                </Row>
            </TabPane>

            <TabPane tab={t('payment-method','Payment Method')} key={'payment-method'}>
                <Row gutter={16}>
                    <Col lg={12} xs={24}>
                        <Card title={<><CreditCardOutlined/> {t('payment-info','Payment Info')}</>} style={{margin:'1em auto', backgroundColor:'white'}}>
                            {org?.invoicing ? (
                                <em>{t('your-account-is-set-to-pay-via-invoice','Your Account is set to pay via Invoice.')}</em>
                            ) : (
                                <Elements stripe={stripePromise}>
                                    <CheckoutForm/>
                                </Elements>
                            )}
                        </Card>
                    </Col>

                    <Col lg={12} xs={24}>
                        {!org?.invoicing && (
                            <Card title={<><InvoiceIcon/> {t('invoicing','Invoicing')}</>} style={{margin:'1em auto', backgroundColor:'white'}}>
                                {t('please-contact-us-at','Please contact us at')} <a href={'mailto:billing@mediagraph.com'}>billing@mediagraph.io</a> {t('if-you-would-like-to-request-invoice-billing',"if you'd like to request Invoice billing")}.
                            </Card>
                        )}
                    </Col>
                </Row>
            </TabPane>

            <TabPane tab={t('invoices','Invoices')} key={'invoices'} id={'invoices'}>
                <Space direction={'vertical'} style={{width:'100%'}}>
                    <Row gutter={16}>
                        <Col lg={12} xs={24}>
                            <UpcomingInvoice/>
                        </Col>
                        <Col lg={12} xs={24}>
                            <BillingEmailForm/>
                        </Col>
                    </Row>
                    <InvoicesTable/>
                </Space>
            </TabPane>

            {/*<TabPane tab={'Updates'} key={'updates'}>Updates</TabPane>*/}
        </Tabs>
    );
}

const CheckoutForm = ({submitForm})=> {
    const {t} = useTranslation();
    const org = useCurrentOrg()
    const {dispatch: sessionDispatch} = useContext(SessionContext);

    const stripe = useStripe();
    const elements = useElements();

    const [cardComplete, setCardComplete] = useState()
    const [loadingStripeToken, setLoadingStripeToken] = useState()

    const handleSubmit = async (event) => {
        setLoadingStripeToken(true)
        // Block native form submission.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        // Use your card Element with other Stripe.js APIs
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            console.log('[error]', error);

            setLoadingStripeToken(false)
        } else {
            console.log('[PaymentMethod]', paymentMethod);

            updatePaymentMethod(paymentMethod.id)
        }
    };

    window.updatePaymentMethod = token => {
        api.post(`/api/organizations/${org.id}/add_payment_method`, {token}).then(res => {
            sessionDispatch({type: 'org_updated', org: res.data});
            setLoadingStripeToken(false)
            message.success(t('message-card-updated','Card updated!'))
            setShowCardForm(false)
        })
    }

    const onCardChange = e =>{
        if(e.complete) setCardComplete(true)
    }

    const card = org?.stripe_details?.card

    const [showCardForm, setShowCardForm] = useState(!card)

    const [removingCard, setRemovingCard] = useState()
    const removeCard = ()=>{
        setRemovingCard(true)
        api.post(`/api/organizations/${org.id}/remove_payment_method`).then(res => {
            setRemovingCard(false)
            sessionDispatch({type: 'set_org', org: res.data});
            message.success(t('message-card-removed','Card removed.'))
        })
    }

    return (
        <form onSubmit={handleSubmit}>
            <Space direction={'vertical'} style={{width:'100%'}}>
                {card && !showCardForm && (
                    <Alert
                        icon={<CreditCardOutlined/>}
                        type={'info'} showIcon
                        message={
                            <>
                                <strong>{card.brand?.toProperCase()}</strong> {t('ending','ending')} **** **** **** {card.last4}
                            </>
                        }
                        action={
                            <Popconfirm
                                title={t('confirm-remove-card','Remove card?')}
                                onConfirm={removeCard}
                            >
                                <Button
                                    icon={<DeleteOutlined/>}
                                    danger
                                    ghost
                                    loading={removingCard}
                                />
                            </Popconfirm>
                        }
                   />
                )}

                {showCardForm && (
                    <>
                        <div style={{border:'1px solid #eee', borderRadius:10, padding:'1em'}}>
                            <CardElement onChange={onCardChange}/>
                        </div>

                        <Button
                            style={{marginTop:'1em'}}
                            disabled={!stripe || !cardComplete}
                            block
                            type={'primary'}
                            icon={<CheckOutlined/>}
                            onClick={handleSubmit}
                            loading={loadingStripeToken}
                        >
                            {t('button-save-card','Save Card')}
                        </Button>

                        {card && (
                            <Button
                                block
                                icon={<CloseCircleOutlined/>}
                                onClick={()=> setShowCardForm(false) }
                            >
                                {t('button-cancel','Cancel')}
                            </Button>
                        )}
                    </>
                )}

                {!showCardForm && (
                    <>
                        <Button
                            block
                            icon={<EditOutlined/>}
                            onClick={()=> setShowCardForm(true) }
                        >
                            {card ? t('change','Change') : t('add','Add')} {t('payment-method','Payment Method')}
                        </Button>
                    </>
                )}
            </Space>
        </form>
    );
}

const InvoicesTable = ({})=>{
    const {t} = useTranslation();
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10});
    const [loading, setLoading] = useState(false);

    const handleTableChange = (pagination, filters, sorter) => {
        setLoading(true);

        const params = {
            sortField: sorter?.field,
            sortOrder: sorter?.order,
            pagination,
            ...filters,
        }
        api.get('/api/invoices', { params: params }).then(res => {
            setData(res.data)
            setPagination({
                ...params.pagination,
                total: res.headers['total-entries']
            })
            setLoading(false)
        })
    }

    const org = useCurrentOrg()

    useEffect(handleTableChange, [org?.updated_at]);

    const statusColors = {
        'paid': 'green',
        'draft': 'yellow',
        'open': 'red'
    }

    const columns = [
        {
            title: t('date','Date'),
            dataIndex: 'created_at',
            sorter: true,
            width: 200,
            render: date => <TimeAgo date={date}/>
        },
        {
            title: t('status','Status'),
            dataIndex: 'status',
            render: status => <Tag color={statusColors[status]}>{t(status, status?.toProperCase())}</Tag>
        },
        {
            title: t('total','Total'),
            dataIndex: 'total',
            sorter: true,
            render: total => (
                <>${(total / 100).toFixed(2)}</>
            )
        },
        {
            title: t('details','Details'),
            width: 100,
            render: invoice => {
                return invoice.stripe_url &&
                    <Button
                        icon={invoice.status == 'open' ? <DollarOutlined /> : <InvoiceIcon/>}
                        href={invoice.stripe_url}
                        target={'_blank'}
                    >
                        {invoice.status == 'open' ? t('button-pay-invoice','Pay Invoice') : t('button-view-download','View/Download')}
                    </Button>
            }
        }
    ];

    return (
        <Table
            size={'small'}
            scroll={{x:true}}
            columns={columns}
            rowKey={record => record.id}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            onChange={handleTableChange}
        />
    )
}


const UpcomingInvoice = ({})=>{
    const {t} = useTranslation();
    const org = useCurrentOrg()

    const [invoice, setInvoice] = useState()
    const [loading, setLoading] = useState(true)
    useEffect(()=>{
        if(!org) return;

        api(`/api/organizations/${org?.id}/upcoming_invoice`).then(res => {
            setInvoice(res.data)
            setLoading(false)
        })
    }, [org?.updated_at])

    return (
        <Skeleton active loading={loading}>
            <Card title={<><ClockCircleOutlined/> {t('upcoming-invoice','Upcoming Invoice')}</>} size={'small'}>
                {invoice && (
                    <>
                        <Descriptions bordered size='small' column={1}>
                            <Descriptions.Item label={t('date','Date')}>
                                <TimeAgo date={moment.unix(invoice.date)}/>
                            </Descriptions.Item>

                            <Descriptions.Item label={t('amount','Amount')}>
                                ${(invoice.amount_due / 100.0).toFixed(2)}
                            </Descriptions.Item>
                        </Descriptions>
                    </>
                )}
            </Card>
        </Skeleton>
    )
}

const CurrentUsage = ({})=>{
    const {t} = useTranslation();
    const org = useCurrentOrg()

    const [usage, setUsage] = useState()
    const [loading, setLoading] = useState(true)
    useEffect(()=>{
        if(!org) return;

        api(`/api/organizations/${org?.id}/current_usage`).then(res => {
            setUsage(res.data)
            setLoading(false)
        })
    }, [org?.updated_at])

    return (
        <Skeleton active loading={loading}>
            <Statistic title={t("storage","Storage")} value={usage || 0} suffix={'GB'} />
        </Skeleton>
    )
}

const BillingEmailForm = ()=>{
    const {t} = useTranslation();
    const org = useCurrentOrg()
    const {dispatch: sessionDispatch} = useContext(SessionContext);

    return (
        <Card title={<><MailOutlined/> Notifications</>} size={'small'}>
            <Formik
                initialValues={org}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                    const data = _.pick(values, 'billing_email')
                    api.patch(`/api/organizations/${org.id}`, {organization: data}).then((res)=>{
                        sessionDispatch({type: 'org_updated', org: res.data});

                        actions.setSubmitting(false)
                        message.success(t(`message-updated`,`Updated!`))

                        actions.resetForm({});

                    }).catch((error)=>{
                        if(error) {
                            console.error(error.response?.data)
                            message.error(`Error: ${JSON.stringify(error.response?.data)}`)
                            actions.setSubmitting(false)
                        }
                    })
                }}
            >
                {({setFieldValue, values, submitForm, dirty, isSubmitting}) => (
                    <Form layout='horizontal'>
                        <Space size={'middle'}>
                            <FormItem name={'billing_email'} label={<>{t('billing-email','Billing Email')} <HelpPopover code={'billing-email'}/></>}>
                                <Input name={'billing_email'}/>
                            </FormItem>

                            <FormItem name={'submit'}>
                                <Button
                                    onClick={submitForm}
                                    icon={<CheckOutlined/>}
                                    type={'primary'}
                                    loading={isSubmitting}
                                    disabled={!dirty}
                                >
                                    {t('button-save','Save')}
                                </Button>
                            </FormItem>
                        </Space>
                    </Form>
                )}
            </Formik>
        </Card>

    )
}